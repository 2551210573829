import {
  CellFormatOptions,
  ColumnTable,
  Icon,
  Table,
  useMobile,
  useWindowSize,
} from "@octano/global-ui";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { CareerStatus } from "../../components/careerSelector/academicInformationTypes";
import { useAcademicInformation } from "../../components/careerSelector/useAcademicInformation";
import TextEllipsis from "../../components/text/TextEllipsis";
import ReportActionsCell from "./parts/ReportActionsCell/ReportActionsCell";
import { getAllCertificates } from "./requests";
import { CertificateType, ReportTypes } from "./types";

const filterColumnsByMobile = (
  onMobile: boolean,
  columns: ColumnTable<CertificateType>[],
) => {
  return columns.filter((column) => {
    if (!onMobile) return true;
    return column.columnName !== "description";
  });
};

const prefix = "reports";

export default function Reports() {
  const { t } = useTranslation();

  const { selectedCareer } = useAcademicInformation();

  const isMobile = useMobile();
  const { width: windowWidth } = useWindowSize();

  const [isLoadingReports, setIsLoadingReports] = useState(false);
  const [certificatesList, setCertificatesList] = useState<CertificateType[]>(
    [],
  );
  const [error, setError] = useState(false);

  const TABLE_COLUMNS = [
    {
      headerText: t(`${prefix}.tableColumns.report`),
      columnName: "name",
    },
    {
      headerText: t(`${prefix}.tableColumns.description`),
      columnName: "description",
      cellFormat: (data: CellFormatOptions<CertificateType>) => {
        return (
          <TextEllipsis
            key={data.index}
            textWidth={windowWidth ? windowWidth * 0.35 : 320}
          >
            {data.row.description}
          </TextEllipsis>
        );
      },
    },
    {
      headerText: t("reports.tableColumns.action"),
      columnName: "action",
      width: !isMobile ? "300px" : undefined,
      cellFormat: (data: CellFormatOptions<CertificateType>) => (
        <ReportActionsCell
          report={data.row}
          studyPlanEnrollmentId={selectedCareer.studyPlanEnrollmentId}
        />
      ),
    },
  ];

  useEffect(() => {
    const fetchReportsList = async () => {
      setIsLoadingReports(true);
      const { data, error: fetchError } = await getAllCertificates();
      if (fetchError) {
        setError(true);
      }
      if (data) {
        setCertificatesList(data);
      }
      setIsLoadingReports(false);
    };

    fetchReportsList();
  }, []);

  const reports = useMemo(() => {
    if (
      selectedCareer.status === CareerStatus.Graduate ||
      selectedCareer.status === CareerStatus.Titled
    ) {
      return certificatesList.filter(
        (x) =>
          x.code === ReportTypes.DegreeStudent ||
          x.code === ReportTypes.GradesStudent ||
          x.code === ReportTypes.EnrolledStudent,
      );
    }
    if (selectedCareer.status === CareerStatus.Regular) {
      return certificatesList.filter(
        (x) => x.code !== ReportTypes.DegreeStudent,
      );
    }
    return certificatesList.filter((x) => x.code === ReportTypes.GradesStudent);
  }, [certificatesList, selectedCareer.status]);

  return (
    <>
      <h6 className="fs-20 text-uppercase fw-700 tc-primary mt-4">
        {t("reports.title")}
      </h6>
      <p className="fs-18 text-light mb-5 mt-3">{t("reports.description")}</p>
      {error ? (
        <div className="d-flex justify-content-center align-items-center text-center">
          <div className="p-5" style={{ maxWidth: 500 }}>
            <Icon name="warning" size="50px" />
            <h1 className="fs-20 mt-4 text-dark">
              {t(`${prefix}.errors.reportsListError.title`)}
            </h1>
            <p className="fs-16 mt-3">
              {t(`${prefix}.errors.reportsListError.subtitle`)}
            </p>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <Table
            data={reports}
            columns={filterColumnsByMobile(isMobile, TABLE_COLUMNS)}
            isLoadingResults={isLoadingReports}
            loadingView={{
              title: t(`${prefix}.loading.generatingFile.title`),
              subtitle: t(`${prefix}.loading.generatingFile.description`),
            }}
            striped
          />
        </div>
      )}
    </>
  );
}
