import { useTranslation } from "react-i18next";
import DisplayError from "../../../components/info/DisplayError";

export default function NoStructureError() {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center align-items-center mt-4">
      <DisplayError
        icon="information"
        title={t(`studentScores.noStructureError`)}
        body=""
      />
    </div>
  );
}
