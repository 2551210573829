import { DEGREE_PROCESSES } from "../../api/endpoints";
import request from "../../api/request";
import { DegreeProcessResponse } from "./types";

export const getDegreeProcess = (studyPlanEnrollmentId: number) => {
  return request<DegreeProcessResponse>(
    DEGREE_PROCESSES.GET_DEGREE_PROCESS(studyPlanEnrollmentId),
    {
      method: "GET",
    },
  );
};
