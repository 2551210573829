import Base from "../../components/base/Base";
import { CareerSelectorProvider } from "../../components/careerSelector/parts/CareerSelectorProvider";
import CourseRegistration from "./CourseRegistration";

const CourseRegistrationView = () => {
  return (
    <Base>
      <CareerSelectorProvider>
        <CourseRegistration />
      </CareerSelectorProvider>
    </Base>
  );
};

export default CourseRegistrationView;
