import { ColumnTable } from "@octano/global-ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DegreeProcessActivity } from "../types";
import BadgeState from "./BadgeState";

export function useColumns({ isMobile }: { isMobile: boolean }) {
  const prefix = "degreeProcesses.tableActivities";
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      headers: t<string, Record<string, string>>(`${prefix}.headers`, {
        returnObjects: true,
      }),
      badgeState: t<string, Record<"pending" | "approved" | "failed", string>>(
        `${prefix}.badgeState`,
        {
          returnObjects: true,
        },
      ),
    }),
    [t],
  );

  const columnsAll = useMemo<
    Array<ColumnTable<DegreeProcessActivity> & { showMobile: boolean }>
  >(() => {
    return [
      {
        showMobile: false,
        columnName: "code",
        width: "40px",
        thClassName: "text-center",
        tdClassName: "text-center",
        headerText: texts.headers.code,
        cellFormat: ({ row }) => row.type.code,
      },
      {
        showMobile: true,
        columnName: "name",
        width: "15%",
        thClassName: "text-center",
        tdClassName: "text-center",
        headerText: texts.headers.activity,
        cellFormat: ({ row }) => row.type.name,
      },
      {
        showMobile: true,
        columnName: "description",
        width: "25%",
        thClassName: "text-center",
        tdClassName: "text-center",
        headerText: texts.headers.description,
        cellFormat: ({ row }) => row.type.description,
      },
      {
        showMobile: false,
        columnName: "status",
        width: "5%",
        thClassName: "text-center",
        tdClassName: "text-center",
        headerText: texts.headers.state,
        cellFormat: ({ row }) => {
          if (!row.state) {
            return (
              <BadgeState status="pending" text={texts.badgeState.pending} />
            );
          }
          if (row.state.rendition.approved) {
            return (
              <BadgeState status="approved" text={texts.badgeState.approved} />
            );
          }
          return <BadgeState status="failed" text={texts.badgeState.failed} />;
        },
      },
      {
        showMobile: true,
        columnName: "grade",
        width: "5%",
        thClassName: "text-center",
        tdClassName: "text-center",
        headerText: texts.headers.grade,
        cellFormat: ({ row }) => {
          if (!row.state) {
            return "-";
          }
          if (!row.state.rendition.grade) {
            return "N/A";
          }
          return row.state.rendition.grade;
        },
      },
      {
        showMobile: false,
        columnName: "gradeMax",
        width: "40px",
        thClassName: "text-center",
        tdClassName: "text-center",
        headerText: texts.headers.gradeMax,
        cellFormat: ({ row }) => {
          if (!row.state) {
            return "-";
          }
          if (!row.state.rendition.gradeMax) {
            return "N/A";
          }
          return row.state.rendition.gradeMax;
        },
      },
    ];
  }, [texts]);

  const columns = useMemo(() => {
    return columnsAll.filter(({ showMobile }) => !isMobile || showMobile);
  }, [columnsAll, isMobile]);

  return columns;
}
