import { Card, Col, Row } from "reactstrap";
import { Button, Icon } from "@octano/global-ui";
import { useCallback } from "react";
import { ViewPetition } from "../../../types/petitions";

export interface PetitionFileDownloadProps {
  file?: ViewPetition["doc"];
  text: string;
  label: string;
}
export default function PetitionFileDownload({
  file,
  label,
  text,
}: PetitionFileDownloadProps) {
  const onDownload = useCallback(() => {
    if (file) {
      const link = document.createElement("a");
      link.download = file.name;
      link.href = file.url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [file]);
  return (
    <Card>
      <label className="g-file-input-label text-left">{label}</label>
      <Row>
        <Col xs={12} sm={6}>
          <div className="g-file-input-container">
            {!!file && (
              <>
                <span>
                  <Icon name="file_img" size="25px" color="primary" />
                </span>
                <span className="g-file-input-name">{file.name}</span>
              </>
            )}
          </div>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            text={text}
            color="primary"
            outlined
            size="md"
            disabled={!file}
            onClick={onDownload}
            icon="download"
            fullwidth
          />
        </Col>
      </Row>
    </Card>
  );
}
