interface Base {
  id: number;
  name: string;
  periodTypeId: string
}

export interface Campus extends Base {}

export interface Schedule extends Base {}

export interface Mentions extends Base {}

export interface Period extends Base {}

export enum CareerStatus {
  Regular = "regular",
  Reincorporated = "reincorporado",
  Postponed = "postergado",
  Resigned = "renunciado",
  InstitutionalRetracted = "retractado_institucional",
  WithdrawnStudyPlan = "retractado_del_plan_de_estudio",
  Suspended = "suspendido",
  Canceled = "anulado",
  Expelled = "expulsado",
  Abandon = "abandono",
  Graduate = "egresado",
  Titled = "titulado",
}

export interface Career extends Base {
  campus: Campus;
  schedule: Schedule;
  mentions: Mentions[];
  studyPlanEnrollmentId: number;
  status: CareerStatus;
  period: Pick<Period, "id" | "name">;
  
}

export const statusOrder = [
  CareerStatus.Regular,
  CareerStatus.Reincorporated,
  CareerStatus.Postponed,
  CareerStatus.Resigned,
  CareerStatus.InstitutionalRetracted,
  CareerStatus.WithdrawnStudyPlan,
  CareerStatus.Suspended,
  CareerStatus.Canceled,
  CareerStatus.Expelled,
  CareerStatus.Abandon,
  CareerStatus.Graduate,
  CareerStatus.Titled,
];
