import {
  CSSProperties,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Placement } from 'popper.js';
import * as React from 'react';
import { uniqueId } from '../../utils/randomId';

export type TextEllipsisOptions = {
  children?: ReactNode;
  textWidth: number | string;
  tooltip?: ReactNode | string;
  tooltipPosition?: Placement;
  style?: object;
};

const TextEllipsis = ({
  children,
  textWidth,
  tooltipPosition = 'bottom',
  tooltip,
  style,
}: TextEllipsisOptions) => {
  const [id] = useState<string>(uniqueId('tooltip-'));

  const [toggle, setToggle] = useState(true);

  const textStyle = useMemo<CSSProperties>(() => {
    return toggle
      ? {
          width: textWidth,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }
      : {
          width: textWidth,
        };
  }, [toggle, textWidth]);

  const onClickEvent = useCallback(() => setToggle(!toggle), [toggle]);

  return (
    <div className="d-flex justify-content-center">
      <p id={id} onClick={onClickEvent} style={{ ...textStyle, ...style }}>
        {children}
      </p>
      {tooltip && (
        <UncontrolledTooltip
          placement={tooltipPosition}
          target={id}
          fade={false}
        >
          {tooltip}
        </UncontrolledTooltip>
      )}
    </div>
  );
};

export default TextEllipsis;
