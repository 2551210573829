import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Button, Schedule, ScheduleType } from "@octano/global-ui";
import { useTranslation } from "react-i18next";
import ModalRemoveSections from "./ModalRemoveSections";
import {
  getModulesWithoutAuth,
  ModuleType,
} from "../../../api/requests/courseModules";
import { useCourseSelectionState } from "../../../hooks/useCourseSelectionState";
import CourseTypeLegends from "../../../components/calendar/CourseTypeLegends";
import SectionsWithSchedule from "../../../components/courseEnrollment/SectionsWithSchedule";
import { SelectedSection } from "../../../types/courseSelectionTypes";

const ActionButtons = () => {
  const prefix = "courseSearching.actionButtons";
  const { t } = useTranslation();
  const [isRemoveAllSections, setIsRemoveAllSections] =
    useState<boolean>(false);

  const [modules, setModules] = useState<ModuleType[] | undefined>(undefined);
  const [downloading, setDownloading] = useState<boolean>(false);
  const { selectedSections } = useCourseSelectionState();

  const getModulesInfo = useCallback(async () => {
    const { data } = await getModulesWithoutAuth();
    if (data) {
      setModules(data.data);
    } else {
      setModules([]);
    }
  }, []);

  const handlePrint = useCallback(async () => {
    try {
      setDownloading(true);
      const print = document.getElementById("print");
      if (print) {
        const doc = new jsPDF("p", "px", "a4");
        print.style.display = "block";
        const canvas = await html2canvas(print);
        const imgCalendar = canvas.toDataURL("image/png");
        const imgWidth = doc.internal.pageSize.width * 0.9;
        const imgHeight = (imgWidth * canvas.height) / canvas.width;
        doc.text(t(`${prefix}.title`), 20, 25);
        doc.addImage(imgCalendar, "PNG", 20, 40, imgWidth, imgHeight);
        print.style.display = "none";
        doc.save(t(`${prefix}.title`) + ".pdf");
      }
    } catch (error) {
      // TODO: Mostrar error al generar el archivo
    } finally {
      setDownloading(false);
    }
  }, [setDownloading, t]);

  // Array con todos los horarios de las secciones inscritas
  const schedules: ScheduleType[] = useMemo(() => {
    let results: ScheduleType[] = [];
    if (selectedSections) {
      selectedSections.forEach((section: SelectedSection) => {
        section.sectionSchedules.forEach((schedule) => {
          results.push({
            sectionId: section.id,
            scheduleId: schedule.id,
            courseCode: section.course.code,
            sectionName: section.name,
            day: schedule.day,
            activityId: schedule.activity.id,
            moduleStartId: schedule.startModule.id,
            moduleEndId: schedule.endModule
              ? schedule.endModule.id
              : schedule.startModule.id,
          });
        });
      });
    }
    return results;
  }, [selectedSections]);

  useEffect(() => {
    getModulesInfo();
  }, [getModulesInfo]);

  return (
    <>
      <Row>
        <Col xs={12} lg={{ size: 4, offset: 4 }} className="py-1 pt-3">
          <Button
            text={t(`${prefix}.downloadSchedule`)}
            onClick={handlePrint}
            size="sm"
            icon="download"
            outlined
            loading={downloading}
            fullwidth
          />
        </Col>
        <Col xs={12} lg={4} className="py-1 pt-3">
          <Button
            text={t(`${prefix}.btnEraseAllItems`)}
            onClick={() => setIsRemoveAllSections(true)}
            outlined
            fullwidth
            size="sm"
          />
        </Col>
      </Row>
      <ModalRemoveSections
        isRemoveAllSections={isRemoveAllSections}
        setIsRemoveAllSections={setIsRemoveAllSections}
      />

      {/* Este es el contenido que se descarga en el PDF*/}
      <div className="print-hidden-container">
        <div id="print" style={{ display: "none", width: "1100px" }}>
          <Schedule schedules={schedules} modules={modules} />
          <br />
          <CourseTypeLegends />
          <br />
          <SectionsWithSchedule sections={selectedSections} />
        </div>
      </div>
    </>
  );
};

export default ActionButtons;
