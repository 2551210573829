import { useMemo } from "react";
import { Col, Row } from "reactstrap";
import { Table } from "@octano/global-ui";
import { useTranslation } from "react-i18next";
import { ColumnTable } from "@octano/global-ui/dist/components/Table/types/TableTypes";
import {
  SectionScheduleType,
  SectionScheduleTypeEnum,
} from "../../types/sectionType";
import { formatClassSchedule } from "../../utils/time";
import sortSectionSchedule from "../../utils/sortSectionSchedule";

type Section = {
  id: number;
  name: string;
  course: {
    id: number;
    code: string;
    name: string;
    credits: number;
    types: {
      id: number;
      name: string;
    }[];
  };
  sectionSchedules: SectionScheduleType[];
};

type SectionsWithScheduleProps = {
  sections: Section[];
};

const SectionsWithSchedule = ({ sections }: SectionsWithScheduleProps) => {
  const prefix = "sectionsSchedule";
  const { t } = useTranslation();

  const columns: ColumnTable<Section>[] = useMemo(
    () => [
      {
        columnName: "id",
        headerText: (
          <div className="text-center">{t(`${prefix}.courseCode`)}</div>
        ),
        cellFormat: (options) => (
          <div className="text-center text-uppercase">
            {options.row.course.code}
          </div>
        ),
      },
      {
        columnName: "id",
        headerText: (
          <div className="text-center">{t(`${prefix}.courseName`)}</div>
        ),
        cellFormat: (options) => (
          <div className="text-center text-capitalize">
            {options.row.course.name}
          </div>
        ),
      },
      {
        columnName: "name",
        headerText: (
          <div className="text-center">{t(`${prefix}.sectionName`)}</div>
        ),
        cellFormat: ({ row }) => (
          <div className="text-center text-uppercase">{row.name}</div>
        ),
      },
      {
        columnName: "id",
        headerText: <div className="text-center">{t(`${prefix}.credits`)}</div>,
        cellFormat: (options) => (
          <div className="text-center">{options.row.course.credits}</div>
        ),
      },
      {
        columnName: "id",
        headerText: <div className="text-center">{t(`${prefix}.type`)}</div>,
        cellFormat: (options) => (
          <div className="text-center text-capitalize">
            {options.row.course.types.map((type, i) =>
              i === 0 ? `${type.name} ` : ` - ${type.name}`,
            )}
          </div>
        ),
      },
      {
        columnName: "id",
        headerText: (
          <div className="text-center">{t(`${prefix}.schedule`)}</div>
        ),
        cellFormat: ({ row }) => (
          <div className="text-center text-capitalize">
            {sortSectionSchedule(row.sectionSchedules)
              .filter(
                (schedule) => schedule.type === SectionScheduleTypeEnum.Default,
              )
              .map((schedule: SectionScheduleType) => {
                return (
                  <div key={`schedule_${schedule.id}`}>
                    <span>
                      {schedule.day}{" "}
                      {formatClassSchedule(
                        schedule.startModule,
                        schedule.endModule,
                      )}{" "}
                      - {schedule.classroom.name}
                    </span>
                    <br />
                  </div>
                );
              })}
          </div>
        ),
      },
    ],
    [t],
  );

  return (
    <Row>
      <Col>
        <div className="bg-light p-4">
          <span className="text-uppercase text-primary fs-20 fw-700">
            {t(`${prefix}.title`)}
          </span>
          <div className="pt-3">
            {sections.length > 0 && (
              <Table
                color="gray"
                size="small"
                columns={columns}
                data={sections}
              />
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SectionsWithSchedule;
