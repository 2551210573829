import { useReducer, createContext } from "react";
import parametersReducer, { initialState } from "../reducers/parametersReducer";
import { ParametersContextType } from "../types/parametersTypes";

export const ParametersContext = createContext({} as ParametersContextType);

const ParametersProvider = (props: any) => {
  const [state, dispatch] = useReducer(parametersReducer, initialState);

  return (
    <ParametersContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ParametersContext.Provider>
  );
};

export default ParametersProvider;
