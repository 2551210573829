export default function sortSectionSchedule<T extends { day: string }>(
  list: T[],
) {
  const days = ["LU", "MA", "MI", "JU", "VI", "SA", "DO"];
  return list
    .map((item) => {
      return {
        dayIndex: days.findIndex((day) => day === item.day.toUpperCase()),
        item,
      };
    })
    .sort((a, b) => a.dayIndex - b.dayIndex)
    .map((item) => item.item);
}
