import { COURSE_ENROLLMENT } from "../../api/endpoints";
import request from "../../api/request";
import { EnrollmentResumeResponse } from "./types";

/**
 * Obtiene las asignaturas inscritas para un alumno/carrera en el período actual
 */
export const requestEnrollmentCourseResume = (
  studyPlanEnrollmentId: string,
) => {
  return request<EnrollmentResumeResponse[]>(
    COURSE_ENROLLMENT.ENROLLMENT_COURSE_RESUME(studyPlanEnrollmentId),
    { method: "GET" },
  );
};
