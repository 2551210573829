import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Table, Icon, useMobile } from "@octano/global-ui";
import StatusesLegend from "./StatusesLegend";
import SummaryCard from "./SummaryCard";
import useStudentAttendanceColumns from "./useStudentAttendanceColumns";
import { getStudentAttendanceSummary } from "../../../api/requests/studentManagement";
import { useUserState } from "../../../hooks/useUserState";
import { StudentAttendanceSummary } from "../../../types/attendance";
import AttendanceReqError from "./AttendanceReqError";
import Loading from "./Loading";

const ITEMS_PER_PAGE = 10;

export default function StudentAttendance({
  sectionId,
}: {
  sectionId: string;
}) {
  const { userData } = useUserState();
  const isMobile = useMobile();

  const { t } = useTranslation();
  const prefix = "studentAttendance";

  const [currentPage, setCurrentPage] = useState(0);
  const tableColumns = useStudentAttendanceColumns();

  const [isLoading, setIsLoading] = useState(false);
  const [errorOcurred, setErrorOcurred] = useState(false);

  const [studentAttendance, setStudentAttendance] =
    useState<StudentAttendanceSummary>();

  useEffect(() => {
    const fetchSummary = async () => {
      setIsLoading(true);

      const { data, error } = await getStudentAttendanceSummary({
        sectionId,
        studentId: userData?.studentId ?? 0,
        page: currentPage,
        itemsPerPage: ITEMS_PER_PAGE,
      });

      setIsLoading(false);

      if (error) return setErrorOcurred(true);

      setStudentAttendance(data || undefined);
    };

    if (userData) fetchSummary();
  }, [userData, currentPage, sectionId]);

  if (isLoading) return <Loading />;
  if (errorOcurred) return <AttendanceReqError />;

  return (
    <>
      <div
        className={`d-flex flex-wrap justify-content-between align-items-end ${
          isMobile ? "mt-4 mb-2 pb-3" : "my-4 pb-4"
        }`}
        style={{ gap: 17 }}
      >
        <SummaryCard
          totalClasses={studentAttendance?.status.totalLessons || 0}
          totalModules={studentAttendance?.status.totalModule || 0}
          totalPresent={studentAttendance?.status.present || 0}
          totalAbsent={studentAttendance?.status.absent || 0}
          totalJustified={studentAttendance?.status.justified || 0}
        />
        <StatusesLegend />
      </div>

      <Table
        data={studentAttendance?.listAssistance.data || []}
        columns={tableColumns}
        pagination={{
          currentPage: currentPage + 1,
          itemsPerPage: ITEMS_PER_PAGE,
          onChangePage: (newPage) => setCurrentPage(newPage - 1),
          totalItems: studentAttendance?.listAssistance.total || 0,
          totalPages: studentAttendance?.listAssistance.total_pages || 0,
        }}
        noResultsText={
          <div className="d-flex flex-column align-items-center justify-content-center pt-5">
            <Icon name="information" size={55} />
            <p className="fs-20 text-dark mt-4">{t(`${prefix}.noClasses`)}</p>
          </div>
        }
      />
    </>
  );
}
