import { Card } from "reactstrap";
import { Button, Icon, IconProps, useMobile } from "@octano/global-ui";
import {
  StudentStatusResponseCode,
  StudentTuitionContinuityStatus,
} from "../../../types/tuitionContinuity";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export interface GoToTuitionMsgProps {
  studyPlanEnrollmentId: number;
  eventStatus?: {
    range: string[];
    validate: boolean;
    status?: StudentStatusResponseCode;
    eventPeriod: {
      id: number;
      name: string;
    };
  };
  validation: boolean;
  blockingStatus: StudentTuitionContinuityStatus;
  infEmail: string;
  goToTuition: (studyPlanEnrollmentId: number) => void;
}

const prefix = "tuitionContinuity.goToTuitionMsg";
export default function GoToTuitionMsg({
  studyPlanEnrollmentId,
  eventStatus,
  validation,
  blockingStatus,
  infEmail,
  goToTuition,
}: GoToTuitionMsgProps) {
  const isMobile = useMobile();
  const { t } = useTranslation();
  const texts = useMemo<{
    title: string;
    body: string;
    icon: IconProps["name"];
  }>(() => {
    if (eventStatus && eventStatus.range.length >= 2) {
      const [start, end] = eventStatus.range;
      return {
        title: t(`${prefix}.${blockingStatus}.title`),
        body: t(`${prefix}.${blockingStatus}.body`, {
          start: dayjs(start).format("DD-MM-YYYY"),
          end: dayjs(end).format("DD-MM-YYYY"),
          mail: infEmail,
          periodName: eventStatus.eventPeriod.name,
        }),
        icon:
          blockingStatus === StudentTuitionContinuityStatus.TuitionCompleted
            ? "success"
            : "information",
      };
    } else {
      const combinationLock =
        blockingStatus === StudentTuitionContinuityStatus.AcademicLockout ||
        StudentTuitionContinuityStatus.FinancialBlockade
          ? "combinate-lock"
          : blockingStatus;
      return {
        title: t(`${prefix}.${blockingStatus}.title`),
        body: t(`${prefix}.${combinationLock}.body`, { mail: infEmail }),
        icon: "information",
      };
    }
  }, [blockingStatus, t, eventStatus, infEmail]);

  const onClick = useCallback(() => {
    goToTuition(studyPlanEnrollmentId);
  }, [goToTuition, studyPlanEnrollmentId]);
  return (
    <Card
      className="d-flex justify-content-center align-item-center mt-5 w-75 mx-auto p-3"
      style={{ background: "#f7f7f7", minHeight: 333 }}
    >
      <Icon
        className="mx-auto"
        name={texts.icon}
        size="65px"
        color="secondary"
      ></Icon>
      <div className="text-center p-3">
        <p className="fs-20 text-dark">{texts.title}</p>
        <p className="fs-18 fw-300">{texts.body}</p>
        {validation && (
          <Button
            text={t(`${prefix}.buttonToTuition`)}
            size="lg"
            style={{ minWidth: isMobile ? "100%" : 220.81 }}
            onClick={onClick}
          />
        )}
      </div>
    </Card>
  );
}
