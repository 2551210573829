import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { useCourseSelectionState } from "../../../hooks/useCourseSelectionState";

const SelectedCredits = () => {
  const prefix = "courseRegistration";
  const { t } = useTranslation();
  const { selectedCredits } = useCourseSelectionState();

  return (
    <div className="align-items-center justify-content-center py-2">
      <div className="outlined-box-primary">
        <Row className="fs-16">
          <Col xs={12} className="d-flex justify-content-between">
            <span className="text-dark fw-600">
              {t(`${prefix}.countSelectedCredits`)}
            </span>
            <span>{selectedCredits}</span>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SelectedCredits;
