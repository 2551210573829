import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "reactstrap";
import { useStepState } from "../../../../hooks/useStepState";
import GoBackButton from "../../parts/GoBackButton";
import { DataOwnSustainer } from "../../../../types/sustainerTypes";
import SustainerForm, { FieldsSustainerForm } from "./parts/SustainerForm";
import { useParameters } from "../../../../hooks/useParameters";
import { useCallback, useEffect, useState } from "react";
import { useLoadingState } from "../../../../hooks/useLoadingState";
import { DefaultValues } from "react-hook-form";
import DisplayError from "../../../../components/DisplayError";
import Loading from "../../../../components/info/Loading";
import { formatDataOwnSustainer, formatDefaultValues } from "./utils";
import { useParams } from "react-router-dom";
import { getSustainer } from "../../../../api/requests/tuitionContinuity";

export default function Sustainer() {
  const { t } = useTranslation();
  const { prevStep } = useStepState();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const [defaultValues, setDefaultValues] =
    useState<DefaultValues<FieldsSustainerForm>>();
  const [dataOwnSustainer, setDataOwnSustainer] = useState<DataOwnSustainer>();
  const {
    communeOptions,
    regionOptions,
    loadRegionsAndCommunes,
    loadCountries,
    countryOptions,
    nationalityOptions,
  } = useParameters();
  const { studyPlanEnrollmentId } = useParams<{
    studyPlanEnrollmentId: string;
  }>();

  useEffect(() => {
    loadRegionsAndCommunes();
  }, [loadRegionsAndCommunes]);

  useEffect(() => {
    loadCountries();
  }, [loadCountries]);

  const getSustainerStepInfo = useCallback(async () => {
    const { data: apiData, error } = await getSustainer(studyPlanEnrollmentId);

    if (apiData) {
      const { data } = apiData;
      setDefaultValues(
        formatDefaultValues(
          data,
          communeOptions,
          regionOptions,
          countryOptions,
          nationalityOptions,
        ),
      );
      setDataOwnSustainer(
        formatDataOwnSustainer(
          data,
          communeOptions,
          regionOptions,
          countryOptions,
          nationalityOptions,
        ),
      );
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
    }
    setLoading(false);
  }, [
    setErrorLoading,
    setLoading,
    communeOptions,
    regionOptions,
    countryOptions,
    nationalityOptions,
    studyPlanEnrollmentId,
  ]);

  useEffect(() => {
    if (loading && countryOptions.length > 0 && nationalityOptions.length > 0) {
      getSustainerStepInfo();
    }
  }, [loading, getSustainerStepInfo, countryOptions, nationalityOptions]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <Card className="px-4 px-md-5 py-4">
      <Row className="pb-4 pb-md-0">
        <Col xs={12} className="pb-3">
          <GoBackButton
            onClick={() => prevStep()}
            text={t(`common.actions.goBackStep`)}
          />
        </Col>
      </Row>

      <SustainerForm
        defaultValues={defaultValues}
        dataOwnSustainer={dataOwnSustainer}
      />
    </Card>
  );
}
