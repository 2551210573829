import { Navbar, NavbarMenuOptionType } from "@octano/global-ui";
import { useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import RoutesByLayout from "../components/routes/RoutesByLayout";
import { PathsLayouts } from "../config/routes";

import { ReactComponent as AddemicProductLogo } from "../assets/addemic/logo_expanded_white.svg";
import { IS_ADDEMIC } from "../config/constants";

const AvailableSectionsLayout = () => {
  const history = useHistory();
  const menuOptions: NavbarMenuOptionType[] = [];

  return (
    <>
      <Navbar
        Logo={() => (
          <div className="cursor-pointer" onClick={() => history.push("/")}>
            {IS_ADDEMIC ? (
              <AddemicProductLogo style={{ width: 160 }} />
            ) : (
              <img
                src="/tenant/logo_expanded_white.svg"
                alt="tenant_logo"
                style={{ maxHeight: 90 }}
              />
            )}
          </div>
        )}
        menuOptions={menuOptions}
      />

      <div className="px-2 px-md-4">
        <Container className="pt-4 pb-5" fluid>
          <RoutesByLayout pathLayout={PathsLayouts.coursesSearching} />
        </Container>
      </div>
    </>
  );
};

export default AvailableSectionsLayout;
