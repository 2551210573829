import { useCallback, useState } from "react";
import {
  PaginationQuery,
  PaginationRequestType,
} from "../types/paginationRequestType";

export interface PaginationProps<T, Q> {
  items?: PaginationRequestType<T>;
  itemsPerPage: number;
  page: number;
  search?: (query?: Q) => void;
  query: Q;
}

export function usePagination<T, Q extends PaginationQuery>({
  search,
  items,
  ...othersProps
}: PaginationProps<T, Q>) {
  const [data, setData] = useState(othersProps);
  const onChangePage = useCallback(
    (page: number) => {
      setData((prevState) => {
        const { page: prevPage, query, ...others } = prevState;
        if (page !== prevPage) {
          if (search) {
            search({
              ...query,
              page: page - 1,
              items_per_page: data.itemsPerPage,
            });
          }
          return {
            page,
            query,
            ...others,
          };
        }
        return prevState;
      });
    },
    [data.itemsPerPage, search],
  );

  const handlerSearch = useCallback(
    (searchData: Partial<Q>) => {
      if (search) {
        search({
          ...data.query,
          items_per_page: data.itemsPerPage,
          page: data.page,
          ...searchData,
        });
      }
    },
    [data.itemsPerPage, data.page, data.query, search],
  );

  return {
    items: items?.data ?? [],
    currentPage: data.page,
    total: items?.total ?? 0,
    itemsPerPage: data.itemsPerPage,
    onChangePage,
    handlerSearch,
    isPaginated: items ? items.total_pages > 1 : false,
    totalPages: items?.total_pages ?? 0,
  };
}
