import { PERIODS } from "../endpoints";
import request from "../request";

export interface CreditPeriodResponse {
  creditMin: number;
  creditMax: number;
  helpEmail: string;
}

/**asdasdasd
 * Valida que el usuario ingresado sea un usuario del producto.
 * @param data
 * @returns
 */
export const getCreditsByPeriod = (studyPlanEnrollmentId: string) => {
  const url = PERIODS.PERIOD_CREDITS;
  const params = { studyPlanEnrollmentId };

  return request<CreditPeriodResponse>(url, {
    method: "get",
    params,
  });
};
