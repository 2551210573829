export interface StudentTuitionContinuityStatusResponse {
  eventStatus?: {
    validate: boolean;
    range: string[];
    status?: StudentStatusResponseCode;
    eventPeriod: {
      id: number;
      name: string;
    };
  };

  name: string;

  validation: boolean;

  blockingStatus: StudentTuitionContinuityStatus;

  studyPlanEnrollmentId: number;

  studyPlanName: string;

  campusName: string;

  scheduleName: string;

  currentPeriodName: string;

  infEmail: string;
}

export enum StudentStatusResponseCode {
  Incoming = "INCOMING_EVENT",
  Exceeded = "PERIOD_EXCEEDED",
}
export enum StudentTuitionContinuityStatus {
  NoBlockages = "no-blockages",
  FinancialBlockade = "financial-blockade",
  AcademicLockout = "academic-lockout",
  EventLocking = "event-locking",
  NoEvent = "no-event",
  TuitionCompleted = "tuition-completed",
  FirstSemesterStudent = "first-semester-student",
}
export interface CurrentStepResponse {
  steps: string[];
  currentStep: string;
}
export enum TcProcessEvent {
  TC_PROCESS_UNAUTHORIZED = "tc_process_unauthorized",
}
