import { Button } from "@octano/global-ui";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { Modal, Icon } from "@octano/global-ui";
import { useCourseSelectionState } from "../../../hooks/useCourseSelectionState";

const ModalRemoveSections = ({
  isRemoveAllSections,
  setIsRemoveAllSections,
}: {
  isRemoveAllSections: boolean;
  setIsRemoveAllSections: (value: boolean) => void;
}) => {
  const prefix = "courseSearching.deletionModal";
  const { t } = useTranslation();
  const { removeAllItems } = useCourseSelectionState();

  return (
    <Modal
      isOpen={isRemoveAllSections}
      toggle={() => setIsRemoveAllSections(false)}
      closeOnBackdrop={false}
    >
      <Row className="text-center">
        <Col xs={12} className="pt-2">
          <Icon name="warning" size="56px" color="secondary" />
        </Col>
        <Col xs={12} className="pt-4 pb-3">
          <span className="fs-22 text-dark">{t(`${prefix}.title`)}</span>
        </Col>
        <Col xs={12}>
          <p className="fs-18 fw-300">{t(`${prefix}.body`)}</p>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col xs={12} sm={6} className="pb-2 px-3 px-sm-2 mx-auto">
          <Button
            onClick={() => setIsRemoveAllSections(false)}
            text={t(`${prefix}.cancel`)}
            fullwidth
            outlined
          />
        </Col>
        <Col xs={12} sm={6} className="pb-2 px-3 px-sm-2 mx-auto">
          <Button
            onClick={() => {
              removeAllItems();
              setIsRemoveAllSections(false);
            }}
            text={t(`${prefix}.confirm`)}
            fullwidth
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalRemoveSections;
