import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CurricularProgressionResponse,
  getCurriculumProgressRequest,
} from "../../../api/requests/curriculum";
import DisplayError from "../../../components/info/DisplayError";
import Loading from "../../../components/info/Loading";
import ProgressionBySemester from "../../../components/progression/ProgressionBySemester";
import ProgressionLegends from "../../../components/progression/ProgressionLegends";
import { useAcademicInformation } from "../../../components/careerSelector/useAcademicInformation";
import { useLoadingState } from "../../../hooks/useLoadingState";
import CurriculumResume from "../../studyProgression/parts/CurriculumResume";

export default function StudentGrid() {
  const { selectedCareer } = useAcademicInformation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const { t } = useTranslation();

  const [data, setData] = useState<CurricularProgressionResponse>();

  const getCurriculumProgress = useCallback(async () => {
    setLoading(true);
    let { data: responseData, error: responseError } =
      await getCurriculumProgressRequest(selectedCareer.studyPlanEnrollmentId);
    if (responseError) {
      setErrorLoading(responseError.code);
    } else if (responseData) {
      setErrorLoading(undefined);
      setData(responseData);
    }
    setLoading(false);
  }, [selectedCareer.studyPlanEnrollmentId, setErrorLoading, setLoading]);

  useEffect(() => {
    getCurriculumProgress();
  }, [getCurriculumProgress]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        body={errorLoading}
        retryAction={() => getCurriculumProgress()}
        loadingAction={loading}
      />
    );
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <div className="mt-5">
        <CurriculumResume data={data as CurricularProgressionResponse} />
      </div>
      <h6 className="fs-20 text-uppercase fw-700 tc-primary mt-5 mb-4">
        {t("curricularAdvancement.CourseStudies.title")}
      </h6>
      <ProgressionBySemester
        semesters={
          data?.semesters as CurricularProgressionResponse["semesters"]
        }
      />
      <div className="mt-4">
        <ProgressionLegends enrolled />
      </div>
    </>
  );
}
