export enum ReportTypes {
  RegularStudent = "regular-student",
  EnrolledStudent = "enrolled-student",
  GradesStudent = "grades-student",
  DegreeStudent = "degree-student",
}

export type ReportType = `${ReportTypes}`;

export type ReportComponents = {
  [key in ReportType]: (reportType: {
    studyPlanEnrollmentId: number;
    reportType: ReportType;
  }) => JSX.Element;
};

export interface CertificateType {
  id: number;
  code: ReportType;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}
