import { ReactElement, useCallback, useEffect, useState } from "react";
import { AxiosResult, AxiosResultDefaultError } from "../../../api/request";
import DisplayError from "../../../components/DisplayError";
import Loading from "../../../components/info/Loading";
import { useLoadingState } from "../../../hooks/useLoadingState";

export interface TuitionContinuityStepLoaderProps<T> {
  request: () => Promise<AxiosResult<T, AxiosResultDefaultError>>;
  children: (props: T) => ReactElement;
}

export function TuitionContinuityStepLoader<T>({
  request,
  children,
}: TuitionContinuityStepLoaderProps<T>) {
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const [data, setData] = useState<T>();

  /* Función que se llama cuando se monta el componente. Trae la info utilizada en la vista */
  const load = useCallback(async () => {
    setLoading(true);

    const response = await request();

    if (response.data) {
      setData(response.data);
      setErrorLoading(undefined);
    }
    if (response.error) {
      setErrorLoading(response.error.code);
    }
    setLoading(false);
  }, [request, setErrorLoading, setLoading]);

  useEffect(() => {
    load();
  }, [load]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => load()}
        loadingAction={loading}
      />
    );
  }

  if (loading) {
    return <Loading insideCard />;
  }

  if (!data) {
    return (
      <DisplayError
        insideCard
        textBody="tariffData no cargado"
        retryAction={() => load()}
        loadingAction={loading}
      />
    );
  }

  return children(data);
}
