import { ColumnTable, useMobile } from "@octano/global-ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils/time";
import { StudentScoreGradeRow } from "../types";
import clsx from "clsx";

export interface UseStudentScoreColumnsOptions<T> {
  isBold?: (row: T) => boolean;
}

export default function useStudentScoreColumns<T extends StudentScoreGradeRow>(
  options?: UseStudentScoreColumnsOptions<T>,
) {
  const prefix = "studentScores";
  const { t } = useTranslation();

  const isMobile = useMobile();

  const isBold = options?.isBold;

  return useMemo(() => {
    const desktop: ColumnTable<T>[] = [
      {
        headerText: t(`${prefix}.table.evaluation`),
        columnName: "name",
        thClassName: "text-center",
        tdClassName: ({ row }) =>
          clsx("text-center", { "fw-700": isBold ? isBold(row) : false }),
      },
      {
        headerText: t(`${prefix}.table.score`),
        columnName: "grade",
        thClassName: "text-center",
        tdClassName: ({ row }) =>
          clsx("text-center", { "fw-700": isBold ? isBold(row) : false }),
        cellFormat: ({ value }) => (isNaN(value) ? value : value?.toFixed(1)),
      },
      {
        headerText: t(`${prefix}.table.percent`),
        columnName: "percentage",
        thClassName: "text-center",
        tdClassName: ({ row }) =>
          clsx("text-center", { "fw-700": isBold ? isBold(row) : false }),
        cellFormat: ({ value }) => `${value}%`,
      },
      {
        headerText: t(`${prefix}.table.date`),
        columnName: "date",
        thClassName: "text-center",
        tdClassName: ({ row }) =>
          clsx("text-center", { "fw-700": isBold ? isBold(row) : false }),
        cellFormat: ({ row }) => (row.date ? formatDate(row.date) : ""),
      },
    ];

    const mobile: ColumnTable<T>[] = [
      {
        headerText: t(`${prefix}.table.evaluation`),
        columnName: "name",
        thClassName: "text-center",
        tdClassName: ({ row }) =>
          clsx("text-center", { "fw-700": isBold ? isBold(row) : false }),
        cellFormat: ({ row }) => `${row.name} (Pon: ${row.percentage}%)`,
      },
      {
        headerText: t(`${prefix}.table.score`),
        columnName: "grade",
        thClassName: "text-center",
        tdClassName: ({ row }) =>
          clsx("text-center", { "fw-700": isBold ? isBold(row) : false }),
        cellFormat: ({ value }) => (isNaN(value) ? value : value?.toFixed(1)),
      },
    ];
    return isMobile ? mobile : desktop;
  }, [t, isMobile, isBold]);
}
