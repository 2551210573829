import { createContext, useReducer } from "react";
import { StepContextType } from "../types/stepTypes";
import stepReducer, { initialState } from "../reducers/stepReducer";

export const StepContext = createContext<StepContextType>({
  state: { currentStep: 0 },
  dispatch: () => 0,
});

const StepProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(stepReducer, initialState);

  return (
    <StepContext.Provider value={{ state, dispatch }}>
      {children}
    </StepContext.Provider>
  );
};

export default StepProvider;
