export enum AttendanceStatus {
  PRESENT = "Present",
  ABSENT = "Absent",
  JUSTIFIED = "Justified",
}

export interface StudentAttendanceSummary {
  status: Status;
  listAssistance: AttendancesList;
}

interface AttendancesList {
  data: AttendanceRecord[];
  total: number;
  total_pages: number;
}

interface AttendanceRecord {
  id: number;
  status: AttendanceStatus;
  observation?: string;
  createdAt: string;
  updatedAt: string;
  student: Student;
  module: Module;
  lesson: Lesson;
}

interface Lesson {
  id: number;
  date: string;
  status: string;
  statusRow?: any;
  dateInitLessons?: string;
  dateClosedLessons?: string;
  createdAt: string;
  updatedAt: string;
  dateRecovery?: any;
  teacher?: Teacher;
}

interface Teacher {
  id: number;
  createdAt: string;
  updatedAt: string;
  account: Account;
}

interface Account {
  email: string;
  name: string;
  paternalLastName: string;
  maternalLastName: string;
  fullName: string;
}

interface Module {
  id: number;
  name: string;
  shortening: string;
  startTime: string;
  endTime: string;
  createdAt: string;
  updatedAt: string;
}

interface Student {
  id: number;
  createdAt: string;
  updatedAt: string;
}

interface Status {
  absent: number;
  justified: number;
  present: number;
  totalLessons: number;
  totalModule: number;
}
