import { SelectOptionType } from "@octano/global-ui";
import { Dispatch } from "react";

export enum ParametersActionType {
  SET_COUNTRIES = "SET_COUNTRIES",
  SET_NATIONALITIES = "SET_NATIONALITIES",
  SET_REGIONS = "SET_REGIONS",
  SET_COMMUNES = "SET_COMMUNES",
  SET_INSTITUTION_TYPES = "SET_INSTITUTION_TYPES",
  SET_PARAMETER_ERROR = "SET_PARAMETER_ERROR",
}

export type ParametersAction =
  | {
      type: ParametersActionType.SET_COUNTRIES;
      payload: SelectOptionType[];
    }
  | {
      type: ParametersActionType.SET_NATIONALITIES;
      payload: SelectOptionType[];
    }
  | {
      type: ParametersActionType.SET_REGIONS;
      payload: SelectOptionType[];
    }
  | {
      type: ParametersActionType.SET_COMMUNES;
      payload: SelectOptionType[];
    }
  | {
      type: ParametersActionType.SET_INSTITUTION_TYPES;
      payload: SelectOptionType[];
    }
  | {
      type: ParametersActionType.SET_PARAMETER_ERROR;
      payload: ParameterErrorType;
    };

export type ParametersState = {
  countryOptions: SelectOptionType[];
  nationalityOptions: SelectOptionType[];
  communeOptions: SelectOptionType[];
  regionOptions: SelectOptionType[];
  institutionTypeOptions: SelectOptionType[];
  parameterErrors: ParameterErrorType;
};

export type ParameterErrorType = {
  country?: string;
  nationality?: string;
  commune?: string;
  region?: string;
  institutionType?: string;
};

export type ParametersContextType = {
  state: ParametersState;
  dispatch: Dispatch<ParametersAction>;
};

export type Region = {
  id: number;
  name: string;
};

export type Commune = {
  id: number;
  name: string;
  regionId: number;
};

export type RegionsAndCommunesBody = {
  regions: Region[];
  communes: Commune[];
};
