import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ColumnTable, useMobile } from "@octano/global-ui";
import StatusBox from "./StatusBox";
import { StudentAttendanceSummary } from "../../../types/attendance";

type AttendanceRecord = StudentAttendanceSummary["listAssistance"]["data"][0];

export default function useStudentAttendanceColumns() {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const formatTimeStr = useCallback((timeStr: string) => {
    const [hours, minutes] = timeStr.split(":");

    const parsedHours = Number(hours);
    const formatedHours = parsedHours > 12 ? parsedHours - 12 : parsedHours;

    return `${formatedHours}:${minutes}`;
  }, []);

  const COLUMNS: ColumnTable<AttendanceRecord>[] = useMemo(() => {
    const desktopColumns: ColumnTable<AttendanceRecord>[] = [
      {
        headerText: t(`common.terms.date`),
        columnName: "date",
        thClassName: "text-center",
        tdClassName: "text-center",
        cellFormat: ({ row }) => row.lesson.date,
      },
      {
        headerText: t(`common.terms.module`),
        columnName: "module",
        thClassName: "text-center",
        tdClassName: "text-center",
        cellFormat: ({ value }) => value.shortening,
      },
      {
        headerText: t(`common.terms.time`),
        columnName: "time",
        thClassName: "text-center",
        tdClassName: "text-center",
        cellFormat: ({ row }) => {
          return `${formatTimeStr(row.module.startTime)} - ${formatTimeStr(
            row.module.endTime
          )}`;
        },
      },
      {
        headerText: t(`common.terms.teacher`),
        columnName: "lesson",
        thClassName: "text-center",
        tdClassName: "text-center",
        cellFormat: ({ value }) => value.account?.fullName,
      },
      {
        headerText: t(`common.terms.status`),
        columnName: "status",
        thClassName: "text-center",
        tdClassName: "d-flex justify-content-center",
        cellFormat: ({ value }) => <StatusBox status={value} />,
      },
    ];

    const mobileColumnNames = ["date", "module", "status"];
    const mobileColumns = desktopColumns.filter((column) =>
      mobileColumnNames.includes(column.columnName)
    );

    return isMobile ? mobileColumns : desktopColumns;
  }, [formatTimeStr, t, isMobile]);

  return COLUMNS;
}
