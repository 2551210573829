import Base from "../../components/base/Base";
import { CareerSelectorProvider } from "../../components/careerSelector/parts/CareerSelectorProvider";
import StudyProgression from "./StudyProgression";

const StudyProgressionView = () => {
  return (
    <Base>
      <CareerSelectorProvider>
        <StudyProgression />
      </CareerSelectorProvider>
    </Base>
  );
};

export default StudyProgressionView;
