const STUDY_PLAN_ENROLLMENT_KEY = "STUDY_PLAN_ENROLLMENT_KEY";

export const setStudyPlanEnrollmentDefault = (studyPlanEnrollmentId: number) =>
  localStorage.setItem(
    STUDY_PLAN_ENROLLMENT_KEY,
    JSON.stringify(studyPlanEnrollmentId),
  );

export function getStudyPlanEnrollmentDefault() {
  const parsed = JSON.parse(
    localStorage.getItem(STUDY_PLAN_ENROLLMENT_KEY) || "null",
  );
  if (!parsed) {
    return null;
  }
  if (typeof parsed !== "number") {
    return null;
  }
  return parsed;
}

export const existStudyPlanEnrollmentDefault = () =>
  Boolean(getStudyPlanEnrollmentDefault());

export const removeStudyPlanEnrollmentDefault = () =>
  localStorage.removeItem(STUDY_PLAN_ENROLLMENT_KEY);
