import { ColumnTable, Table } from "@octano/global-ui";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import SectionTitle from "../../../../../components/text/SectionTitle";
import { moneyFormatter } from "../../../../../utils/currency";
import { formatDate } from "../../../../../utils/time";
import { TariffCalculateResponse } from "./tariffTypes";

interface ITariffTableProps {
  data?: TariffCalculateResponse;
  loading: boolean;
}

export default function TariffTable({
  loading,
  data: tariffCalculate,
}: ITariffTableProps) {
  const prefix = "tuitionContinuity.tariffQuotas";
  const { t } = useTranslation();

  const printClassName = (index: number, data: any[], className?: string) => {
    return clsx(
      className,
      index === data.length - 1 &&
        "h-100 d-flex justify-content-center align-items-center",
    );
  };

  const columns: ColumnTable[] = [
    {
      columnName: "description",
      headerText: t(`${prefix}.table.quota`),
      width: "20%",
      tdClassName: "p-0",
      cellFormat({ row, data, index }) {
        if (index === data!.length - 1)
          return (
            <div className={printClassName(index, data!, "bg-tertiary")}>
              <p className="text-primary m-auto">
                <strong>{row.description}</strong>
              </p>
            </div>
          );
        return row.description;
      },
    },
    {
      columnName: "endDate",
      headerText: t(`${prefix}.table.expirationDate`),
      width: "20%",
      tdClassName: "p-0",
      cellFormat({ row, data, index }) {
        if (index === data!.length - 1)
          return (
            <div className={printClassName(index, data!, "bg-tertiary")} />
          );
        return formatDate(row.endDate);
      },
    },
    {
      columnName: "amount",
      headerText: t(`${prefix}.table.amount`),
      width: "20%",
      tdClassName: "p-0",
      cellFormat({ row, data, index }) {
        if (index === data!.length - 1)
          return (
            <div className={printClassName(index, data!, "bg-tertiary")}>
              <p className="text-primary m-auto">
                <strong>{moneyFormatter().format(row.amount)}</strong>
              </p>
            </div>
          );
        return moneyFormatter().format(row.amount);
      },
    },
  ];
  return (
    <Row>
      <Col xs={12} className="py-3">
        <SectionTitle text={t(`${prefix}.table.title`)} />
      </Col>
      <Col xs={12}>
        <div className="w-75 mx-auto px-4 ">
          <div className="text-center">
            <Table
              isLoadingResults={loading}
              columns={columns}
              data={
                tariffCalculate
                  ? [
                      ...tariffCalculate.installments,
                      {
                        description: "Total",
                        endDate: "",
                        amount: tariffCalculate.total,
                      },
                    ]
                  : []
              }
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
