import { Icon } from "@octano/global-ui";
import { useTranslation } from "react-i18next";

const HeaderSectionList = ({ goBackAction }: { goBackAction: () => void }) => {
  const prefix = "sectionList";
  const { t } = useTranslation();
  return (
    <div className="px-2 d-flex justify-content-between">
      <div
        className="cursor-pointer align-items-center"
        onClick={goBackAction}
        style={{ width: "fit-content" }}
      >
        <Icon name="chevron_left" color="inherit" size={12} />
        <span className="pl-2">{t(`${prefix}.goBack`)}</span>
      </div>
      <div>
        <span>{t(`${prefix}.selectSection`)}</span>
      </div>
    </div>
  );
};

export default HeaderSectionList;
