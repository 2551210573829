import { Button, Modal, Icon } from "@octano/global-ui";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useUserState } from "../../../hooks/useUserState";

interface Props {
  isOpen: boolean;
}

export default function NotRegularStModal({ isOpen }: Props) {
  const { t } = useTranslation();
  const prefix = "reports.errors.notAllowedModal";
  const { resetUserState } = useUserState();
  const history = useHistory();

  const logOut = () => {
    resetUserState();
    history.push("/");
  };

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div className="d-flex flex-column align-items-center justify-content-center text-center py-3 px-4">
        <Icon name="error" size={50} className="mb-4" />
        <h1 className="fs-20 text-dark mt-2 mb-3">{t(`${prefix}.title`)}</h1>
        <p className="fs-18 text-light">{t(`${prefix}.description`)}</p>
        <Button
          fullwidth
          className="mt-5"
          text={t(`common.btnGotIt`)}
          onClick={() => logOut()}
        />
      </div>
    </Modal>
  );
}
