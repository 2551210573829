import { addToast, Button, Modal } from "@octano/global-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Card, Col, Form, Row } from "reactstrap";
import { viewPetition } from "../../../api/requests/petitions";
import { useBase } from "../../../components/base/BaseProvider";
import Loading from "../../../components/info/Loading";
import { useLoadingState } from "../../../hooks/useLoadingState";
import { PetitionData, ViewPetition } from "../../../types/petitions";
import PetitionForm from "./PetitionForm";

export interface ViewPetitionModalProps {
  onClose: () => void;
  petitionId?: number;
}
export default function ViewPetitionModal({
  petitionId,
  onClose,
}: ViewPetitionModalProps) {
  const methods = useForm<PetitionData>();
  const { reset } = methods;
  const [petition, setPetition] = useState<ViewPetition>();
  const { loading, setLoading } = useLoadingState();
  const { t } = useTranslation();

  const text = useMemo(() => {
    const prefix = "petitions.modal.viewPetition";
    return {
      title: t(`${prefix}.title`),
      button: t(`${prefix}.button`),
      error: t(`${prefix}.error`),
    };
  }, [t]);

  const getPetitionData = useCallback(
    async (petitionId: number) => {
      setLoading(true);
      const { data, error } = await viewPetition(petitionId);
      if (data) {
        setPetition(data);
      } else if (error) {
        addToast({
          icon: "error",
          color: "danger",
          text: text.error,
        });
      }
      setLoading(false);
    },
    [setLoading, text],
  );

  const { careers } = useBase();

  useEffect(() => {
    if (petitionId) {
      getPetitionData(petitionId);
    }
  }, [petitionId, getPetitionData]);
  useEffect(() => {
    if (petition) {
      const career = careers.find(
        (c) => c.studyPlanEnrollmentId === petition.studyPlanEnrollmentId,
      );
      reset({
        studyPlanEnrollment: {
          label: career!.name,
          value: career!.studyPlanEnrollmentId,
        },
        type: { label: petition.type.name, value: petition.type.id },
        cellPhone: petition.phone,
        title: petition.title,
        description: petition.description,
        email: petition.email,
        resolved: petition.resolved?.comment,
      });
    }
  }, [petition, careers, reset]);
  return (
    <Modal isOpen={!!petitionId} toggle={onClose} size="lg">
      <Card className=" mx-4">
        <Row>
          <Col md={12} className="text-center">
            <h6 className="fs-20 fw-400 ">{text.title}</h6>
          </Col>
          <Col>
            {loading ? (
              <Loading />
            ) : (
              <FormProvider {...methods}>
                <Form>
                  <Row className="mt-4 mb-1">
                    <PetitionForm
                      downloadFile={petition?.doc}
                      downloadFileResponse={petition?.docResponse}
                      onlyView={true}
                      isResolved={!!petition?.resolved}
                    />
                    <Col md={12}>
                      <Button
                        type="button"
                        fullwidth={true}
                        onClick={onClose}
                        text={text.button}
                      />
                    </Col>
                  </Row>
                </Form>
              </FormProvider>
            )}
          </Col>
        </Row>
      </Card>
    </Modal>
  );
}
