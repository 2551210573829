import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { Schedule, ScheduleType } from "@octano/global-ui";
import { useCourseSelectionState } from "../../../hooks/useCourseSelectionState";
import { getModules, ModuleType } from "../../../api/requests/courseModules";
import CourseTypeLegends from "../../../components/calendar/CourseTypeLegends";
import { SectionScheduleTypeEnum } from "../../../types/sectionType";

const WeekCalendar = () => {
  const { selectedSections } = useCourseSelectionState();
  const [modules, setModules] = useState<ModuleType[]>();

  const getModulesInfo = useCallback(async () => {
    const { data } = await getModules();
    if (data) {
      setModules(data.data);
    }
  }, []);

  useEffect(() => {
    getModulesInfo();
  }, [getModulesInfo]);

  const schedules = useMemo(() => {
    let results: ScheduleType[] = [];

    // Se crea array con todos los horarios de las secciones seleccionadas
    selectedSections.forEach((section) => {
      section.sectionSchedules
        .filter((schedule) => schedule.type === SectionScheduleTypeEnum.Default)
        .forEach((schedule) => {
          results.push({
            sectionId: section.id,
            scheduleId: schedule.id,
            courseCode: section.course.code,
            sectionName: section.name,
            day: schedule.day,
            activityId: schedule.activity.id,
            moduleStartId: schedule.startModule.id,
            moduleEndId: schedule.endModule
              ? schedule.endModule.id
              : schedule.startModule.id,
          });
        });
    });
    return results;
  }, [selectedSections]);

  return (
    <Row>
      <Col xs={12}>
        <Schedule schedules={schedules} modules={modules} />
      </Col>
      <Col xs={12} className="pt-3">
        <CourseTypeLegends />
      </Col>
    </Row>
  );
};

export default WeekCalendar;
