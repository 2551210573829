import { useReducer, createContext, FunctionComponent } from "react";
import searchingCoursesReducer, {
  initialState,
} from "../reducers/searchingCoursesReducer";
import { SearchingCoursesContextType } from "../types/courseSearchingTypes";

export const SearchingCoursesContext = createContext(
  {} as SearchingCoursesContextType
);

const SearchingCoursesProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(searchingCoursesReducer, initialState);

  return (
    <SearchingCoursesContext.Provider value={{ state, dispatch }}>
      {children}
    </SearchingCoursesContext.Provider>
  );
};

export default SearchingCoursesProvider;
