import { TextInput } from "@octano/global-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

import SectionTitle from "../../../../../components/text/SectionTitle";
import { TariffData } from "./tariffTypes";

interface ITariffQuotaDisplayDataProps {
  tariffData: Omit<TariffData, "quotas">;
  children: JSX.Element;
}

export default function TariffDisplayData({
  tariffData,
  children,
}: ITariffQuotaDisplayDataProps) {
  const prefix = "tuitionContinuity.tariffQuotas";
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col xs={12} className="pb-3">
          <SectionTitle text={t(`${prefix}.displayData.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="studyPlan"
            label={t(`${prefix}.displayData.studyPlan`)}
            disabled
            value={tariffData.studyPlan}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="campus"
            label={t(`${prefix}.displayData.campus`)}
            disabled
            value={tariffData.campus}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="schedule"
            label={t(`${prefix}.displayData.schedule`)}
            disabled
            value={tariffData.schedule}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="tariff"
            label={t(
              `${prefix}.displayData.${
                tariffData.hasDiscount ? "discountedTariff" : "tariff"
              }`,
            )}
            disabled
            value={tariffData.feeTariff}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="payDay"
            label={t(`${prefix}.displayData.payDay`)}
            disabled
            value={tariffData.payday.description}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          {children}
        </Col>
      </Row>
    </>
  );
}
