import { addToast, Button, useMobile } from "@octano/global-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { HTTP_STATUS_CODE } from "../../../../api/request";
import { downloadFromURL } from "../../../../utils/blob";
import { ReportType } from "../../types";
import GeneratingFileModal from "../GeneratingFileModal";
import NoResultsModal from "../NoResultsModal";
import NotRegularStModal from "../NotRegularStModal";
import SelectPeriodModal from "../SelectPeriodModal";
import { createReportDownloadHandler } from "./handlerCreator";

enum ModalType {
  NONE,
  GENERATING_FILE,
  NO_CERTIFICATE,
  NOT_REGULAR_STUDENT,
  SELECT_PERIOD,
}

interface IEnrolledStudentActionsProps {
  studyPlanEnrollmentId: number;
  reportType: ReportType;
}

export default function EnrolledStudentActions({
  studyPlanEnrollmentId,
  reportType,
}: IEnrolledStudentActionsProps) {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const [openedModal, setOpenedModal] = useState<ModalType>();

  const handleReportDownload = createReportDownloadHandler({
    beforeDownload: () => setOpenedModal(ModalType.GENERATING_FILE),
    afterDownload: ({ status }: { url: string; status: number }) => {
      if (status === HTTP_STATUS_CODE.NO_CONTENT) {
        setOpenedModal(ModalType.NO_CERTIFICATE);
      } else {
        setOpenedModal(ModalType.NONE);
      }
    },
    onSuccess: ({ url }) => {
      if (url) {
        downloadFromURL(url);
      }
    },
    onError: (err) => {
      if (err.status !== HTTP_STATUS_CODE.NO_CONTENT) {
        addToast({
          icon: "error",
          color: "danger",
          text: t(`reports.errors.errorGeneratingFile`),
        });
      }
    },
  });

  return (
    <>
      <NotRegularStModal
        isOpen={openedModal === ModalType.NOT_REGULAR_STUDENT}
      />
      <NoResultsModal
        isOpen={openedModal === ModalType.NO_CERTIFICATE}
        onConfirm={() => setOpenedModal(ModalType.NONE)}
        texts={{
          title: t(`reports.enrolledStudent.noResults.description`),
          description: "",
        }}
      />
      <GeneratingFileModal isOpen={openedModal === ModalType.GENERATING_FILE} />
      <SelectPeriodModal
        isOpen={openedModal === ModalType.SELECT_PERIOD}
        onConfirm={({ periodId }: { periodId?: string | number }) => {
          setOpenedModal(ModalType.NONE);
          handleReportDownload(studyPlanEnrollmentId, reportType, {
            periodId,
          });
        }}
        onClose={() => setOpenedModal(ModalType.NONE)}
        texts={{
          title: t(`reports.enrolledStudent.selectPeriod.title`),
          description: t(`reports.enrolledStudent.selectPeriod.description`),
        }}
      />

      <div
        className="d-flex flex-wrap align-items-center justify-content-center "
        style={{ gap: 12 }}
      >
        <Button
          text={t(`common.actions.download`)}
          icon="download"
          size="sm"
          outlined
          onClick={() => setOpenedModal(ModalType.SELECT_PERIOD)}
          style={{ width: isMobile ? "90%" : 123 }}
        />
      </div>
    </>
  );
}
