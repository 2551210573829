import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

import {
  STATUS_TO_ENROLL,
  StatusToEnroll,
} from "../../../api/requests/studentManagement";
import { SectionTeacher } from "../../../types/sectionType";
import { formatDate } from "../../../utils/time";
import CoursesBoxes from "./CoursesBoxes";
import CoursesCarousel from "./CoursesCarousel";
import GoToEnrollmentMsg from "./GoToEnrollmentMsg";

export type CourseRow = {
  id: number;
  shortening: string;
  name: string;
  section: string;
  credits: number;
  professors: SectionTeacher[];
};

export interface Props {
  courses: CourseRow[];
  errorLoading: boolean;
  statusToEnroll: StatusToEnroll;
}

export default function CoursesList({
  courses,
  errorLoading,
  statusToEnroll,
}: Props) {
  const prefix = "myCourses.enrollment";
  const { t } = useTranslation();

  const contentCourses = useMemo(() => {
    if (statusToEnroll.code === STATUS_TO_ENROLL.OK || courses.length > 0) {
      // Si está ok, puede ir a inscribir asignaturas o
      // Si ya inscribió se mostrarán los cursos inscritos
      return <CoursesBoxes courses={courses} errorLoading={errorLoading} />;
    } else if (statusToEnroll.code === STATUS_TO_ENROLL.NOT_FOUND) {
      return (
        <GoToEnrollmentMsg
          title={t(`${prefix}.notFoundPeriod`)}
          body={
            <Trans
              i18nKey={`${prefix}.notFoundPeriodBody`}
              values={{
                email: statusToEnroll.email,
              }}
            />
          }
        />
      );
    } else if (statusToEnroll.code === STATUS_TO_ENROLL.NOT_DEFINE) {
      return (
        <GoToEnrollmentMsg
          title={t(`${prefix}.notDefineTitle`)}
          body={
            <Trans
              i18nKey={`${prefix}.notDefineBody`}
              values={{
                email: statusToEnroll.email,
              }}
            />
          }
        />
      );
    } else if (statusToEnroll.code === STATUS_TO_ENROLL.INCOMING) {
      // Si está ANTES del plazo se indica que la inscripción está pendiente
      // con las fechas del próximo periodo
      return (
        <GoToEnrollmentMsg
          title={t(`${prefix}.pendingTitle`)}
          body={
            <Trans
              i18nKey={`${prefix}.pendingBody`}
              values={{
                startDate: statusToEnroll.range
                  ? formatDate(statusToEnroll.range[0], "DD-MM-YYYY")
                  : "",
                endDate: statusToEnroll.range[1]
                  ? formatDate(statusToEnroll.range[1], "DD-MM-YYYY")
                  : "",
              }}
            />
          }
        />
      );
    } else if (statusToEnroll.code === STATUS_TO_ENROLL.EXCEEDED) {
      // Si está DESPUÉS del plazo se indica que la inscripción está fuera de plazo
      return (
        <GoToEnrollmentMsg
          title={t(`${prefix}.exceededTitle`)}
          body={
            <Trans
              i18nKey={`${prefix}.exceededBody`}
              values={{
                email: statusToEnroll.email,
                startDate: statusToEnroll.range[0]
                  ? formatDate(statusToEnroll.range[0], "DD-MM-YYYY")
                  : "",
                endDate: statusToEnroll.range[1]
                  ? formatDate(statusToEnroll.range[1], "DD-MM-YYYY")
                  : "",
              }}
            />
          }
        />
      );
    } else {
      // Está en un estado distinto de regular ó está con bloqueo financiero
      return (
        <GoToEnrollmentMsg
          title={t(`${prefix}.disabledTitle`)}
          body={
            <Trans
              i18nKey={`${prefix}.disabledBody`}
              values={{
                email: statusToEnroll.email,
              }}
            />
          }
        />
      );
    }
  }, [courses, t, errorLoading, statusToEnroll]);

  return (
    <>
      <Row>
        <Col>
          <h2 className="fs-20 fw-600 text-primary text-uppercase">
            {t(`myCourses.coursesListTitle`)}
          </h2>
          <p className="fs-16 text-medium mb-4">
            {t(`myCourses.coursesListDescription`)}
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={{ size: 12, order: 2 }} md={{ size: 8, order: 1 }}>
          {contentCourses}
        </Col>
        <Col
          xs={{ size: 12, order: 1 }}
          md={{ size: 4, order: 1 }}
          className="mb-4"
        >
          <CoursesCarousel />
        </Col>
      </Row>
    </>
  );
}
