import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useMobile } from "@octano/global-ui";

interface Props {
  totalClasses: number;
  totalModules: number;
  totalPresent: number;
  totalAbsent: number;
  totalJustified: number;
}

export default function SummaryCard({
  totalClasses = 0,
  totalModules = 0,
  totalPresent = 0,
  totalAbsent = 0,
  totalJustified = 0,
}: Props) {
  const { t } = useTranslation();
  const prefix = "studentAttendance";

  const isMobile = useMobile();

  const totalFulfilled = totalPresent + totalJustified;
  const fulfillmentPercentage = (totalFulfilled * 100) / totalModules;

  return (
    <Card
      className="bg-transparent border border-primary px-4 py-3"
      style={{
        width: isMobile ? "100%" : 345,
      }}
    >
      <h2 className="text-secondary fs-20 fw-600 ">
        {t(`common.terms.attendance`)}
      </h2>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          gap: "min(3vw, 40px)",
          flexWrap: isMobile ? "wrap" : "nowrap",
        }}
      >
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ gap: 20 }}
        >
          <div>
            <h4 className="fs-14 text-light">{t(`${prefix}.totalClasses`)}:</h4>
            <h4 className="fs-14 text-light">{t(`${prefix}.totalModules`)}:</h4>
            <h4 className="fs-14 text-light">{t(`${prefix}.totalPresent`)}:</h4>
            <h4 className="fs-14 text-light">{t(`${prefix}.totalAbsent`)}:</h4>
            <h4 className="fs-14 text-light">
              {t(`${prefix}.totalJustified`)}:
            </h4>
          </div>
          <div>
            <h4 className="fs-14 text-light">{totalClasses}</h4>
            <h4 className="fs-14 text-light">{totalModules}</h4>
            <h4 className="fs-14 text-light">{totalPresent}</h4>
            <h4 className="fs-14 text-light">{totalAbsent}</h4>
            <h4 className="fs-14 text-light">{totalJustified}</h4>
          </div>
        </div>
        <div
          className="d-flex flex-column align-items-center justify-content-center py-4 px-3"
          style={{
            background: "#F7F7F7",
            width: isMobile ? "100%" : 115,
            height: 102,
          }}
        >
          <h3 className="text-primary mb-1" style={{ fontSize: 35 }}>
            {isNaN(fulfillmentPercentage)
              ? 100
              : Math.round(fulfillmentPercentage)}
            %
          </h3>
          <p className="mb-0 fs-12">{t(`${prefix}.totalAttendance`)}</p>
        </div>
      </div>
    </Card>
  );
}
