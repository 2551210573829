import { CertificateType, ReportType } from "./types";
import { STUDENT_CERTIFICATES } from "../../api/endpoints";
import request from "../../api/request";

/**
 * Pide la lista de reportes disponibles para descargar
 * @returns
 */

export const getAllCertificates = () => {
  const url = STUDENT_CERTIFICATES.GET_ALL;
  return request<CertificateType[]>(url);
};

/**
 * Descarga el archivo de reporte del url especificado
 * @param reportType El trozo final del endpoint que identifica de donde se decarga el documnento
 * @returns
 */
export const downloadCertificate = (
  studyPlanEnrollmentId: string | number,
  reportType: ReportType,
  queryParams?: any,
) => {
  const url = STUDENT_CERTIFICATES.DOWNLOAD_BY_URL(
    studyPlanEnrollmentId,
    reportType,
  );
  return request(url, { params: queryParams });
};
