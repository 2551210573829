import { Card, Container } from "reactstrap";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  useMobile,
  Tab,
  Tabs,
  TabsList,
  TabPanel,
  TabPanels,
} from "@octano/global-ui";
import TopBar from "../../components/topBar/TopBar";
import SectionDetailBox from "./parts/SectionDetailBox";
import StudentAttendance from "./parts/StudentAttendance";
import StudentScore from "./parts/StudentScore";

type RouterState = {
  course: {
    id: number;
    shortening: string;
    name: string;
    section: string;
    credits: number;
    professors: string;
  };
  section: string;
};

export default function CourseDetail() {
  const { t } = useTranslation();
  const { state } = useLocation<RouterState>();
  const history = useHistory();
  const isMobile = useMobile();

  if (!state?.course) {
    history.push("/student-management/courses");
  }

  return (
    <>
      <TopBar title={t(`myCourses.topBarTitle`)} />
      <Container fluid>
        <Card className="py-4 px-4">
          <div
            className="d-flex flex-wrap justify-content-between mb-5"
            style={{ gap: isMobile ? 12 : 0 }}
          >
            <Button
              text={t(`common.btnBack`)}
              icon="back"
              size="sm"
              outlined
              rounded
              style={{ width: "fit-content", height: "fit-content" }}
              onClick={() => history.goBack()}
            />

            <SectionDetailBox
              course={state.course.name}
              shortening={state.course.shortening}
              section={state.course.section}
              professors={state.course.professors}
            />
          </div>
          <Tabs>
            <TabsList>
              <Tab>{t(`studentScores.tabTitle`)}</Tab>
              <Tab>{t(`studentAttendance.tabTitle`)}</Tab>
            </TabsList>

            <TabPanels>
              <TabPanel>
                <StudentScore sectionId={state.section} />
              </TabPanel>
              <TabPanel>
                <StudentAttendance sectionId={state.section} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Card>
      </Container>
    </>
  );
}
