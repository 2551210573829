import { COURSE_ENROLLMENT } from "../endpoints";
import request from "../request";

export interface creditSummaryType {
  totalCredits: {
    obligatory: number;
    optional: number;
    others: number;
    total: number;
  };
  approvedCredits: {
    obligatory: number;
    optional: number;
    others: number;
    total: number;
  };
  pendingCredits: {
    obligatory: number;
    optional: number;
    others: number;
    total: number;
  };
}

/**
 * Valida que el usuario ingresado sea un usuario del producto.
 * @param data
 * @returns
 */
export const getCreditSummary = (studyPlanEnrollmentId: number | string) => {
  const url = COURSE_ENROLLMENT.CREDITS_SUMMARY;

  const params = { studyPlanEnrollmentId };
  return request<{ data: creditSummaryType }>(url, {
    method: "get",
    params,
  });
};
