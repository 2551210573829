import request from "../request";
import { PETITIONS } from "../endpoints";
import {
  PaginationQuery,
  PaginationRequestType,
} from "../../types/paginationRequestType";
import {
  CreatePetitionData,
  PetitionInf,
  CreatePetition,
  ViewPetition,
} from "../../types/petitions";

export const getPetitionsList = (query: PaginationQuery) =>
  request<PaginationRequestType<PetitionInf>>(PETITIONS.LIST, {
    method: "GET",
    params: query,
  });

export const getPetitionCreationData = () =>
  request<CreatePetitionData>(PETITIONS.FORM, { method: "GET" });

export const createPetition = (data: CreatePetition) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value instanceof File) formData.append(key, value, value.name);
    else if (value) {
      formData.append(key, value);
    }
  });
  return request(PETITIONS.CREATE, { method: "POST", data: formData });
};

export const viewPetition = (petitionId: number) => {
  return request<ViewPetition>(PETITIONS.VIEW(petitionId), { method: "GET" });
};
