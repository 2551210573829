import { downloadCertificate } from "../../requests";
import { ReportType } from "../../types";

type BaseHandlerParams = {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
};

type ReportDownloadHandlerParams = BaseHandlerParams & {
  afterDownload?: ({ data, error }: any) => void;
  beforeDownload?: () => void;
};

export const createReportDownloadHandler = ({
  beforeDownload = () => null,
  afterDownload = () => null,
  onError = () => null,
  onSuccess = () => null,
}: ReportDownloadHandlerParams) => {
  return async (
    studyPlanEnrollmentId: number,
    reportType: ReportType,
    params?: any,
  ) => {
    beforeDownload();
    const { data, error, status } = await downloadCertificate(
      studyPlanEnrollmentId,
      reportType,
      params,
    );
    if (error) {
      onError(error);
    } else {
      onSuccess(data);
    }

    afterDownload({ data, error, status: status ?? error });
  };
};
