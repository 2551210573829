export function blobToFile(blob: Blob, fileName: string): File {
  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type: blob.type,
  });
}

export function downloadFromBlob(blob: Blob, qualifiedName: string) {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", qualifiedName);
  link.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    link.remove();
  }, 0);
}

export function downloadFromURL(url: string) {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    link.remove();
  }, 0);
}
