import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { getSignDocumentationInfo } from "../../../../api/requests/tuitionContinuity";
import SignDocumentation from "./parts/SignDocumentation";
import {
  Document,
  isDocumentType,
  isIdentityType,
} from "./parts/signDocumentationTypes";
import { TuitionContinuityStepLoader } from "../../parts/TuitionContinuityStepLoader";
import { Col, Row } from "reactstrap";
import GoBackButton from "../../parts/GoBackButton";
import SectionTitle from "../../../../components/text/SectionTitle";
import { useStepState } from "../../../../hooks/useStepState";
import { useTranslation } from "react-i18next";

export default function SignDocumentationStep() {
  const { prevStep } = useStepState();

  const prefix = "tuitionContinuity.signDocumentation";
  const { t } = useTranslation();

  const { studyPlanEnrollmentId: studyPlanEnrollmentIdRaw } = useParams<{
    studyPlanEnrollmentId: string;
  }>();
  const studyPlanEnrollmentId = parseInt(studyPlanEnrollmentIdRaw);

  const request = useCallback(async () => {
    return await getSignDocumentationInfo(studyPlanEnrollmentId);
  }, [studyPlanEnrollmentId]);

  return (
    <>
      <Row className="pb-3 pb-lg-4">
        <Col xs={12} lg={6} className="pb-3">
          <GoBackButton
            onClick={() => prevStep()}
            text={t(`common.actions.goBackStep`)}
          />
        </Col>
      </Row>
      <SectionTitle text={t(`${prefix}.displayData.followIntructionsTitle`)} />
      <p className="fs-16">
        {t(`${prefix}.displayData.followIntructionsDescription`)}
      </p>
      <TuitionContinuityStepLoader request={request}>
        {(data) => {
          const documents: Document[] = [];
          for (const document of data.documents) {
            if (isDocumentType(document.type)) {
              documents.push({
                type: document.type,
                required: document.required,
                isUploaded: document.isUploaded,
              });
            }
            if (isIdentityType(document.type)) {
              documents.push({
                type: document.type,
                required: document.required,
                isUploaded: document.isUploaded,
              });
            }
          }
          return (
            <SignDocumentation
              studyPlanEnrollmentId={studyPlanEnrollmentId}
              documents={documents}
            />
          );
        }}
      </TuitionContinuityStepLoader>
    </>
  );
}
