import {
  SearchingCoursesAction,
  SearchingCoursesActionTypes,
  SearchingCoursesState,
} from "../types/courseSearchingTypes";

export const initialState: SearchingCoursesState = {
  studyPlans: undefined,
  courses: undefined,
  sections: undefined,
};

const searchingCoursesReducer = (
  state: SearchingCoursesState,
  action: SearchingCoursesAction
): SearchingCoursesState => {
  switch (action.type) {
    case SearchingCoursesActionTypes.SET_STUDY_PLANS:
      return {
        ...state,
        studyPlans: action.payload,
      };

    case SearchingCoursesActionTypes.SET_COURSES:
      return {
        ...state,
        courses: action.payload,
      };

    case SearchingCoursesActionTypes.SET_SECTIONS:
      return {
        ...state,
        sections: action.payload,
      };

    default:
      return state;
  }
};

export default searchingCoursesReducer;
