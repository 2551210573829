import {
  CourseSelectionAction,
  CourseSelectionActionTypes,
  CourseSelectionState,
} from "../types/courseSelectionTypes";

export const initialState: CourseSelectionState = {
  student: null,
  campusCareer: null,
  selectedCampus: undefined,
  selectedCourse: null,
  selectedSections: [],
  selectedCredits: 0,
  creditsPeriod: {
    creditMin: 0,
    creditMax: 0,
  },
  selectedCareer: null,
};

const courseSelectionReducer = (
  state: CourseSelectionState,
  action: CourseSelectionAction
): CourseSelectionState => {
  switch (action.type) {
    case CourseSelectionActionTypes.RESET_STATE:
      return {
        ...initialState,
      };

    case CourseSelectionActionTypes.SET_CAMPUS_CAREER:
      return {
        ...state,
        campusCareer: action.payload,
      };

    case CourseSelectionActionTypes.SET_COURSE:
      return {
        ...state,
        selectedCourse: action.payload,
      };

    case CourseSelectionActionTypes.ADD_SECTION:
      // Valida que no exista anteriormente en el listado
      if (state.selectedSections.some((s) => s.id === action.payload.id)) {
        return { ...state };
      } else {
        // Se busca si existe seccion seleccionada de la misma asignatura
        const removeByCourse = state.selectedSections.find(
          (s) => s.course.id === action.payload.course.id
        );
        // Se filtra el listado removiendo otras secciones seleccionadas de la asignatura
        // y se inserta la nueva sección seleccionada
        const newSections = [
          ...state.selectedSections.filter(
            (section) => section.course.id !== action.payload.course.id
          ),
          action.payload,
        ].sort((a, b) => {
          // Se ordena el array con la nueva sección agregada
          // de forma ascendente por la sigla de la asignatura
          if (a.course.code.toUpperCase() > b.course.code.toUpperCase()) {
            return 1;
          } else if (
            a.course.code.toUpperCase() < b.course.code.toUpperCase()
          ) {
            return -1;
          } else {
            return 0;
          }
        });

        return {
          ...state,
          selectedSections: newSections,
          // Se suman créditos sólo si no había sección seleccionada anteriormente
          // de lo contrario como las secciones tienen la misma cantidad de créditos
          // se mantiene igual
          selectedCredits:
            state.selectedCredits +
            (!removeByCourse ? action.payload.course.credits : 0),
        };
      }

    case CourseSelectionActionTypes.ADD_SECTION_ALLOWING_REPEATED:
      // Valida que no exista anteriormente en el listado
      if (state.selectedSections.some((s) => s.id === action.payload.id)) {
        return { ...state };
      } else {
        // Se busca si existe seccion seleccionada de la misma asignatura
        const removeByCourse = state.selectedSections.find(
          (s) => s.course.id === action.payload.course.id
        );
        // Se filtra el listado removiendo otras secciones seleccionadas de la asignatura
        // y se inserta la nueva sección seleccionada
        const newSections = [...state.selectedSections, action.payload].sort(
          (a, b) => {
            // Se ordena el array con la nueva sección agregada
            // de forma ascendente por la sigla de la asignatura
            if (a.course.code.toUpperCase() > b.course.code.toUpperCase()) {
              return 1;
            } else if (
              a.course.code.toUpperCase() < b.course.code.toUpperCase()
            ) {
              return -1;
            } else {
              return 0;
            }
          }
        );

        return {
          ...state,
          selectedSections: newSections,
          // Se suman créditos sólo si no había sección seleccionada anteriormente
          // de lo contrario como las secciones tienen la misma cantidad de créditos
          // se mantiene igual
          selectedCredits:
            state.selectedCredits +
            (!removeByCourse ? action.payload.course.credits : 0),
        };
      }

    case CourseSelectionActionTypes.REMOVE_SECTION:
      const sectionRemove = state.selectedSections.find(
        (s) => s.id === action.payload
      );
      return {
        ...state,
        selectedSections: state.selectedSections.filter(
          (section) => section.id !== action.payload
        ),
        selectedCredits:
          state.selectedCredits - (sectionRemove?.course.credits ?? 0),
      };

    case CourseSelectionActionTypes.SELECT_CAMPUS:
      return {
        ...state,
        selectedCampus: action.payload,
      };

    case CourseSelectionActionTypes.REMOVE_ALL_SECTIONS:
      return {
        ...state,
        selectedSections: [],
        selectedCredits: 0,
      };

    case CourseSelectionActionTypes.SET_CREDITS_PERIOD:
      return {
        ...state,
        creditsPeriod: action.payload,
      };

    case CourseSelectionActionTypes.SET_STUDENT_INFO:
      return {
        ...state,
        student: action.payload,
      };

    case CourseSelectionActionTypes.SET_SELECTED_CAREER:
      return {
        ...state,
        selectedCareer: action.payload,
      };

    default:
      return state;
  }
};

export default courseSelectionReducer;
