import { useMemo } from "react";
import { useCareerSelector } from "./parts/CareerSelectorProvider";

export function useAcademicInformation() {
  const value = useCareerSelector();
  if (!value) {
    throw new Error(
      "No hay un valor en el contexto: AcademicInformationContext",
    );
  }
  return useMemo(() => {
    if (!value.selectedCareer) {
      throw new Error("No hay un carrera seleccionable");
    }
    return {
      careers: value.careers,
      selectedCareer: value.selectedCareer,
      selectedPeriod: value.selectedPeriod,
    };
  }, [value]);
}
