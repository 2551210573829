import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { useBase } from "../../base/BaseProvider";
import DisplayError from "../../info/DisplayError";
import { Career, CareerStatus, Period, statusOrder } from "../academicInformationTypes";
import { getStudyPlanEnrollmentDefault } from "../studyPlanEnrollmentDefault";

export const CareerSelectorContext = createContext<{
  careers: Career[];
  selectedCareer: Career | null;
  setSelectedCareer: (career: Career) => void;
  selectedPeriod: Period | null
  setSelectedPeriodByCarrer: (career: Career) => void;
} | null>(null);

export function useCareerSelector() {
  const value = useContext(CareerSelectorContext);
  if (!value) {
    throw new Error("Data no cargada en el context: CareerSelectorContext");
  }
  return value;
}

export function CareerSelectorConsumer({
  children,
}: {
  children: (state: {
    careers: Career[];
    selectedCareer: Career;
    selectedPeriod: Period | null;
  }) => ReactElement;
}) {
  const value = useCareerSelector();

  if (!value.selectedCareer) {
    return <DisplayError body={"No hay una carrera disponible para mostrar"} />;
  }

  return children(value as any);
}

/**
 * SortFunction toma dos objetos Career y los ordena a partir del orden
 * definido en statusOrder.
 * @param {Career} a - Career
 * @param {Career} b - Career - el seg undo objeto de Carrera para comparar
 */
function sortFunction(a: Career, b: Career) {
  return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
}

export function CareerSelectorProvider({
  allowedStatus,
  children,
}: {
  allowedStatus?: CareerStatus[];
  children: ReactNode;
}) {
  const { careers: baseCareers, periods } = useBase();

  const careers = useMemo(() => {
    if (!allowedStatus) {
      return [...baseCareers].sort(sortFunction);
    } else {
      return [...baseCareers]
        .filter((c) => allowedStatus.includes(c.status))
        .sort(sortFunction);
    }
  }, [baseCareers, allowedStatus]);

  const initCareer = useCallback(() => {    
    if (!careers.length) {
      return null;
    }

    const defaultStudyPlanEnrollment = getStudyPlanEnrollmentDefault();
    const careerFound = careers.find(
      (c) => c.studyPlanEnrollmentId === defaultStudyPlanEnrollment,
    );
    if (careerFound) {
      return careerFound;
    } else {
      return careers[0];
    }
  }, [careers])

  const initPeriod = useCallback((career)=>{
    if (!periods?.length) {
      return null;
    }
    
    const periodFound = periods.find(
      (c) => c.periodTypeId === career?.period.periodTypeId,
      );
    if (periodFound) {
      return periodFound;
    } else {
      return null;
    }
  }, [periods])

  const [selectedCareer, setSelectedCareer] = useState(initCareer);
  const [selectedPeriod, setSelectedPeriod] = useState(initPeriod(selectedCareer));

  const handleSelectedPeriodByCarrer = useCallback((career)=>{
    setSelectedPeriod(initPeriod(career))
  }, [initPeriod])

  const value = useMemo(() => {
    return {
      careers,
      selectedCareer,
      setSelectedCareer: (career: Career) => setSelectedCareer(career),
      selectedPeriod,
      setSelectedPeriodByCarrer: (career: Career) => handleSelectedPeriodByCarrer(career)
    };
  }, [careers, selectedCareer, selectedPeriod, handleSelectedPeriodByCarrer]);

  return <CareerSelectorContext.Provider value={value} children={children} />;
}
