import { Box, useMobile } from "@octano/global-ui";
import { useTranslation } from "react-i18next";

export interface Props {
  course: string;
  shortening: string;
  section: string;
  professors: string;
}

export default function SectionDetailBox({
  course,
  shortening,
  section,
  professors,
}: Props) {
  const isMobile = useMobile();
  const { t } = useTranslation();
  const prefix = "courseDetail";

  return (
    <Box
      color="primary"
      variant="outlined"
      style={{
        borderRadius: 4,
        width: "100%",
        maxWidth: isMobile ? "100%" : "65%",
      }}
      className="py-2 px-3"
      body={
        <div
          style={{ gap: 12 }}
          className={`d-flex justify-content-between py-2 ${
            isMobile ? "flex-wrap" : ""
          }`}
        >
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 "
            title={t(`${prefix}.courseName`)}
            body={<p className="fs-14 mb-0">{course}</p>}
            style={{ minWidth: "unset", width: isMobile ? "100%" : "unset" }}
          />
          {!isMobile && (
            <Box
              color="primary"
              variant="outlined"
              className="p-0 border-0"
              title={t(`${prefix}.shortening`)}
              body={<p className="fs-14 mb-0">{shortening}</p>}
              style={{ minWidth: "unset" }}
            />
          )}
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0"
            title={t(`${prefix}.section`)}
            body={<p className="fs-14 mb-0">{section}</p>}
            style={{ minWidth: "unset", maxWidth: isMobile ? "40%" : "unset" }}
          />
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0"
            title={t(`${prefix}.professor`)}
            body={<p className="fs-14 mb-0">{professors}</p>}
            style={{
              minWidth: "unset",
              maxWidth: isMobile ? "60%" : "200px",
            }}
          />
        </div>
      }
    />
  );
}
