import { useTranslation } from "react-i18next";
import validator from "validator";
import { clean, validate } from "rut.js";
import { CountryCode, isValidPhoneNumber } from "libphonenumber-js";

export const useValidations = () => {
  const { t } = useTranslation();
  const prefixValidations = "common.validations";
  const msgValidations = {
    required: t(`${prefixValidations}.required`),
    invalidPhone: t(`${prefixValidations}.invalidPhone`),
    invalidEmail: t(`${prefixValidations}.invalidEmail`),
    invalidRut: t(`${prefixValidations}.invalidRut`),
    invalidPassword: t(`${prefixValidations}.invalidPassword`),
    invalidRepeatPassword: t(`${prefixValidations}.invalidRepeatPassword`),
  };

  /**
   * Valida el formato de la contraseña
   * @param password
   * @returns
   */
  const validatePassword = (password: string) => {
    return password.length >= 8 ? undefined : msgValidations.invalidPassword;
  };

  /**
   * Esta funcion retorna otra funcion de validacion que retorna si la password pasada por parametros es igual a la contraseña repetida
   * @param password
   * @returns
   */
  const validateRepeatPassword =
    (password: string) => (repeatPassword: string) => {
      return password === repeatPassword
        ? undefined
        : msgValidations.invalidRepeatPassword;
    };

  /**
   * Valida que telefono tenga formato +XXXXXXXXXXX
   * @param phone
   * @param code
   * @returns
   */
  const validatePhone = (
    phone: string | undefined,
    code: CountryCode = "CL",
  ) => {
    if (phone && phone !== "") {
      const cleanPhone = phone.replaceAll(" ", "");
      if (!isValidPhoneNumber(cleanPhone, code)) {
        return msgValidations.invalidPhone;
      }
    }
    return undefined;
  };

  /**
   * Valida que el texto ingresado tenga formato de correo
   * @param email
   * @returns
   */
  const validateEmail = (email: string | undefined) => {
    if (email && !validator.isEmail(email)) {
      return msgValidations.invalidEmail;
    }
    return undefined;
  };

  /**
   * Función que valida que el texto ingresado no sea vacío
   * y que no contenga sólo espacios.
   * @param value
   * @returns
   */
  const validateTextNotEmpty = (value: string | undefined) => {
    if (!value || value?.trim().length === 0) {
      return msgValidations.required;
    }
    return undefined;
  };

  /**
   * Función que valida que el texto ingresado sea un rut válido
   * @param value
   * @returns
   */
  const validateRut = (value: string | undefined) => {
    if (value && !validate(clean(value))) {
      return msgValidations.invalidRut;
    }
    return undefined;
  };

  return {
    validatePhone,
    validateEmail,
    validateTextNotEmpty,
    validateRut,
    validatePassword,
    validateRepeatPassword,
    msgValidations,
  };
};
