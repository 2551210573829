import TopBar from "../../components/topBar/TopBar";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "reactstrap";
import { PaginationQuery } from "../../types/paginationRequestType";
import { getPetitionsList } from "../../api/requests/petitions";
import FetchProvider from "../../components/contexts/FetchContext";
import PetitionTable from "./parts/PetitionTable";
import CreatePetitionBtnModal from "./parts/CreatePetitionBtnModal";
import Base from "../../components/base/Base";

export default function Petitions() {
  const { t } = useTranslation();
  const getPetitionList = useCallback(
    (query: PaginationQuery) => getPetitionsList(query),
    [],
  );
  const text = useMemo(() => {
    const prefix = "petitions";
    return {
      title: t(`${prefix}.title`),
      subtitle: t(`${prefix}.subtitle`),
      addBtn: t(`${prefix}.addBtn`),
      info: t(`${prefix}.info`),
    };
  }, [t]);
  return (
    <Base>
      <TopBar title={text.title} />
      <Card className="p-4 mx-3">
        <h6 className="fs-20 text-uppercase fw-700 tc-primary ">
          {text.subtitle}
        </h6>
        <p className="fs-18 text-light mb-5 mt-3">{text.info}</p>
        <FetchProvider
          defaultQuery={{ items_per_page: 10, page: 0 }}
          request={getPetitionList}
          fetchImmediately
        >
          <Row>
            <Col md={4} xs={12}>
              <CreatePetitionBtnModal textBtn={text.addBtn} />
            </Col>
          </Row>
          <PetitionTable />
        </FetchProvider>
      </Card>
    </Base>
  );
}
