import { Redirect } from "react-router-dom";
import { PathsLayouts } from "../config/routes";
import { useUserState } from "../hooks/useUserState";
import RoutesByLayout from "../components/routes/RoutesByLayout";

const AuthLayout = () => {
  const { isLogged } = useUserState();

  if (isLogged) {
    return <Redirect to={PathsLayouts.studentManagement} />;
  }
  return (
    <>
      <RoutesByLayout pathLayout={PathsLayouts.auth} />
    </>
  );
};

export default AuthLayout;
