import { Collapse, ColumnTable, Table } from "@octano/global-ui";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

import {
  CourseValidatedHistorySection,
  CourseHistorySection,
  CourseStatusTypes,
} from "../../../types/curricularAdvancementTypes";

type CourseStatusEnumType = {
  [key in CourseStatusTypes]: {
    class: string;
    text: string;
  };
};

export const CourseStatus: CourseStatusEnumType = {
  E: { class: "box-E", text: "enrolled" },
  A: { class: "box-A", text: "approved" },
  P: { class: "box-P", text: "pending" },
  R: { class: "box-R", text: "failed" },
  V: { class: "box-V", text: "validated" },
};

export interface ICourseHistoryAccordeonProps {
  courses: CourseValidatedHistorySection[];
  average?: string | number;
  title: string;
}

export default function CourseHistoryAccordeon({
  courses,
  average,
  title,
}: ICourseHistoryAccordeonProps) {
  const { t } = useTranslation();
  const hasSummary = average !== undefined;

  const columns: ColumnTable<Partial<CourseHistorySection>>[] = useMemo(
    () => [
      {
        columnName: "courseShorting",
        headerText: t("curricularAdvancement.courseHistory.table.shortening"),
      },
      {
        columnName: "courseName",
        headerText: t("curricularAdvancement.courseHistory.table.name"),
      },
      {
        columnName: "courseCredits",
        headerText: t("curricularAdvancement.courseHistory.table.credits"),
      },
      {
        columnName: "studentSectionStatus",
        headerText: t("curricularAdvancement.courseHistory.table.status"),
        width: "15%",
        cellFormat: ({ row: course, data, index }) => {
          if (hasSummary && data!.length - 1 === index)
            return (
              <div className="d-flex pl-5 ">
                <p className="m-auto">
                  <strong>
                    {t(
                      "curricularAdvancement.courseHistory.table.semesterAverage",
                    )}
                  </strong>
                </p>
              </div>
            );
          return (
            <div className="d-flex justify-content-start pl-5">
              <Row className="g-legend-progression d-flex">
                <Col xs={6} sm="auto" className="g-status-container pr-4 py-2">
                  <div
                    className={`box-legend ${
                      CourseStatus[course.studentSectionStatus!].class
                    } mr-2`}
                  />
                  {t(
                    `curricularAdvancement.CourseStudies.status.${
                      CourseStatus[course.studentSectionStatus!].text
                    }`,
                  )}
                </Col>
              </Row>
            </div>
          );
        },
      },
      {
        columnName: "gradeAvg",
        headerText: t("curricularAdvancement.courseHistory.table.score"),
        cellFormat: ({ row: course, data, index }) => {
          if (hasSummary && data!.length - 1 === index) {
            return (
              <p className="m-auto">
                <strong>
                  {average ??
                    t("curricularAdvancement.courseHistory.table.noScore")}
                </strong>
              </p>
            );
          }

          return (
            <p>
              {course.grade ??
                t("curricularAdvancement.courseHistory.table.noScore")}
            </p>
          );
        },
      },
    ],
    [t, average, hasSummary],
  );

  return (
    <div className="pb-4">
      <Collapse
        headerStyles={{ backgroundColor: "#f7f7f7", height: 50 }}
        contentClassName="pt-4 text-center"
        renderTitle={() => (
          <p className="fs-18  fw-600 my-auto text-primary">{title}</p>
        )}
      >
        <Table
          columns={columns}
          data={hasSummary ? [...courses, {}] : courses}
          striped
        />
      </Collapse>
    </div>
  );
}
