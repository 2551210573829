import { addToast, Button } from "@octano/global-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  Document,
  DocumentTypes,
  DocumentTypesEnum,
  GeneralTypes,
  isIdentityType,
} from "./signDocumentationTypes";
import DocumentCard from "./DocumentCard";
import SectionTitle from "../../../../../components/text/SectionTitle";
import {
  getSignDocumentationDocumentGenerated,
  saveSignDocumentationInfo,
} from "../../../../../api/requests/tuitionContinuity";
import { useStepState } from "../../../../../hooks/useStepState";

function isMissing({
  required,
  isUploaded,
}: {
  required: boolean;
  isUploaded: boolean;
}) {
  return required && !isUploaded;
}

export interface SignDocumentationProps {
  studyPlanEnrollmentId: number;
  documents: Document[];
}

/**
 * Componente que corresponde a la vista principal del paso de contrato y subida de archivos
 */
const SignDocumentation = ({
  studyPlanEnrollmentId,
  documents: documentsProp,
}: SignDocumentationProps) => {
  const prefix = "tuitionContinuity.signDocumentation";
  const { nextStep } = useStepState();
  const [isSubmitting, setSubmitting] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  const [documents, setDocuments] = useState<Document[]>([...documentsProp]);

  const [showErrors, setShowErrors] = useState<boolean>(false);
  const requiredMsgError = t(`${prefix}.errorRequired`);

  const getIndex = useCallback(
    (type: DocumentTypes) => documents.findIndex((doc) => doc.type === type),
    [documents],
  );

  const indexContract = useMemo(
    () => getIndex(DocumentTypesEnum.contract),
    [getIndex],
  );

  const indexLEM = useMemo(() => getIndex(DocumentTypesEnum.lem), [getIndex]);
  const indexNEM = useMemo(() => getIndex(DocumentTypesEnum.nem), [getIndex]);

  useEffect(() => window.scrollTo(0, 0));

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    if (documents.some((doc) => isMissing(doc))) {
      setShowErrors(true);
      setSubmitting(false);
      return;
    }
    setShowErrors(false);
    const { error } = await saveSignDocumentationInfo(studyPlanEnrollmentId);
    if (error) {
      addToast({
        icon: "error",
        color: "danger",
        text: t(`${prefix}.nextStepError`),
      });
      return;
    }
    setSubmitting(false);
    nextStep();
  }, [documents, studyPlanEnrollmentId, nextStep, t, setSubmitting]);

  const onUploadDoc = (indexDoc: number) => {
    setDocuments((prevDocs) => {
      let newDocs = [...prevDocs];
      newDocs[indexDoc].isUploaded = true;
      return newDocs;
    });
  };

  const getErrorTextDoc = useCallback(
    (doc: { type: GeneralTypes; required: boolean; isUploaded: boolean }) => {
      return showErrors && isMissing(doc) ? requiredMsgError : undefined;
    },
    [showErrors, requiredMsgError],
  );

  const downloadContract = useCallback(async () => {
    const { data, error } = await getSignDocumentationDocumentGenerated(
      studyPlanEnrollmentId,
    );
    if (data && !error) {
      window.open(data.url);
    } else if (error) {
      addToast({
        icon: "error",
        color: "danger",
        text: t(`common.errors.download`),
      });
    }
  }, [t, studyPlanEnrollmentId]);

  const identifications = useMemo(() => {
    return documents
      .map((doc, index) => ({ ...doc, index }))
      .filter((d) => isIdentityType(d.type));
  }, [documents]);

  return (
    <>
      <Row>
        <Col xs={12} className="pb-3">
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>

      {/* Listado de documentos a adjuntar */}
      <Row className="mx-n2 align-items-end">
        {indexContract > -1 && (
          <Col xs={12} md={6} lg={4} className="mb-3 px-2">
            <DocumentCard
              tuitionContinuityProcessId={studyPlanEnrollmentId}
              size="md"
              docName={"contract"}
              isUploaded={documents[indexContract].isUploaded}
              errorText={getErrorTextDoc(documents[indexContract])}
              onDownload={downloadContract}
              onUploaded={() => onUploadDoc(indexContract)}
            />
          </Col>
        )}
        {indexLEM > -1 && (
          <Col xs={12} md={6} lg={2} className="mb-3 px-2">
            <DocumentCard
              tuitionContinuityProcessId={studyPlanEnrollmentId}
              docName={"lem"}
              isUploaded={documents[indexLEM].isUploaded}
              errorText={getErrorTextDoc(documents[indexLEM])}
              onUploaded={() => onUploadDoc(indexLEM)}
            />
          </Col>
        )}
        {indexNEM > -1 && (
          <Col xs={12} md={6} lg={2} className="mb-3 px-2">
            <DocumentCard
              tuitionContinuityProcessId={studyPlanEnrollmentId}
              docName={"nem"}
              isUploaded={documents[indexNEM].isUploaded}
              errorText={getErrorTextDoc(documents[indexNEM])}
              onUploaded={() => onUploadDoc(indexNEM)}
            />
          </Col>
        )}

        {/* ADJUNTAR DOCUMENTACIÓN DE CARNET Ó PASAPORTE */}
        {identifications.map((identification, key) => {
          return (
            <Col
              xs={12}
              sm={6}
              lg={2}
              className="mb-3 px-2"
              key={`identification_${key}`}
            >
              <DocumentCard
                tuitionContinuityProcessId={studyPlanEnrollmentId}
                docName={identification.type}
                isUploaded={identification.isUploaded}
                errorText={getErrorTextDoc(identification)}
                onUploaded={() => onUploadDoc(identification.index)}
                optional={!identification.required}
              />
            </Col>
          );
        })}
      </Row>

      <Row className="py-5 justify-content-end">
        <Col xs={12} lg={4} className="pb-2 order-2 order-lg-1">
          <Button
            type="button"
            outlined
            onClick={() => history.push("/tuition-process")}
            text={t(`common.actions.cancel`)}
            loading={isSubmitting}
            fullwidth
          />
        </Col>

        <Col xs={12} lg={4} className="pb-2 order-1 order-lg-2">
          <Button
            text={t(`common.actions.next`)}
            onClick={onSubmit}
            loading={isSubmitting}
            fullwidth
          />
        </Col>
      </Row>
    </>
  );
};

export default SignDocumentation;
