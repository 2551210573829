import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "reactstrap";
import CareerSelector from "../../components/careerSelector/CareerSelector";
import DisplayError from "../../components/info/DisplayError";
import TopBar from "../../components/topBar/TopBar";
import { CareerStatus } from "../../components/careerSelector/academicInformationTypes";
import DegreeProcess from "./parts/DegreeProcess";
import DegreeProcessLoader from "./parts/DegreeProcessLoader";
import Base from "../../components/base/Base";
import { CareerSelectorConsumer } from "../../components/careerSelector/parts/CareerSelectorProvider";

const dictPrefix = "degreeProcesses";

export default function DegreeProcesses() {
  const { t } = useTranslation();

  const texts = useMemo(() => {
    return {
      title: t(`${dictPrefix}.title`),
      subtitle: t(`${dictPrefix}.subtitle`),
      description: t(`${dictPrefix}.description`),
      noActivities: t(`${dictPrefix}.noActivities`),
      regularInfo: {
        title: t(`${dictPrefix}.regularInfo.title`),
        body: t(`${dictPrefix}.regularInfo.body`),
      },
    };
  }, [t]);

  return (
    <Base>
      <TopBar title={texts.title} />
      <Card className="p-4 mx-3">
        <CareerSelector
          allowedStatus={[
            CareerStatus.Regular,
            CareerStatus.Graduate,
            CareerStatus.Titled,
          ]}
        >
          <Row>
            <Col>
              <h2 className="fs-20 fw-600 text-primary text-uppercase">
                {texts.subtitle}
              </h2>
              <p className="fs-16 text-medium mb-4">{texts.description}</p>
            </Col>
          </Row>
          <CareerSelectorConsumer>
            {({ selectedCareer }) => (
              <>
                {[CareerStatus.Graduate, CareerStatus.Titled].includes(
                  selectedCareer.status,
                ) ? (
                  <DegreeProcessLoader
                    studyPlanEnrollmentId={selectedCareer.studyPlanEnrollmentId}
                  >
                    {({ data }) => (
                      <DegreeProcess
                        summary={data.degreeProcess.summary}
                        activities={data.degreeProcess.activities}
                        texts={texts}
                      />
                    )}
                  </DegreeProcessLoader>
                ) : (
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <DisplayError
                      icon="information"
                      title={texts.regularInfo.title}
                      body={texts.regularInfo.body}
                    />
                  </div>
                )}
              </>
            )}
          </CareerSelectorConsumer>
        </CareerSelector>
      </Card>
    </Base>
  );
}
