import {
  DataOwnSustainer,
  SustainerStepResponse,
} from "../../../../types/sustainerTypes";
import { SelectOptionType } from "@octano/global-ui";
import { DefaultValues } from "react-hook-form";
import { FieldsSustainerForm } from "./parts/SustainerForm";
import { getSelectedOption } from "../../../../utils/selectFormat";
import {
  maritalStatusOptions,
  RELATIONSHIP_OWN_SUSTAINER,
  relationshipOptions,
} from "../../../../utils/selectOptions";
import { format } from "rut.js";

const formatDefaultValues = (
  data: SustainerStepResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
  countryOptions: SelectOptionType[],
  nationalityOptions: SelectOptionType[],
): DefaultValues<FieldsSustainerForm> => {
  const { sustainer, student } = data;
  const selectedCommune = getSelectedOption(
    sustainer?.communeId,
    communeOptions,
  );

  return {
    hasPassport: !!sustainer?.passportNumber,
    relationship: relationshipOptions.find(
      (row) => row.value === sustainer?.relationshipId,
    ),
    occupation: sustainer?.occupation,
    names: sustainer?.names,
    paternalLastName: sustainer?.paternalLastName,
    maternalLastName: sustainer?.maternalLastName,
    rut: sustainer?.rut ? format(sustainer.rut) : "",
    passportCountry: getSelectedOption(
      sustainer?.passportCountry,
      countryOptions,
    ),
    passportNumber: sustainer?.passportNumber,
    addressStreet: sustainer?.addressStreet,
    addressNumber: sustainer?.addressNumber,
    addressExtra: sustainer?.addressExtra,
    commune: selectedCommune,
    region: getSelectedOption(selectedCommune?.regionId, regionOptions),
    phone: sustainer?.phone,
    cellphone: sustainer?.cellPhone,
    email: sustainer?.email,
    ownSustainer: sustainer?.rut === student.rut ? true : undefined,
    nationality: getSelectedOption(
      sustainer?.nationality.id,
      nationalityOptions,
    ),
    maritalStatus: getSelectedOption(
      sustainer?.maritalStatusId,
      maritalStatusOptions,
    ),
  };
};

const formatDataOwnSustainer = (
  data: SustainerStepResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
  countryOptions: SelectOptionType[],
  nationalityOptions: SelectOptionType[],
): DataOwnSustainer => {
  const { student } = data;
  const selectedCommune = getSelectedOption(student?.communeId, communeOptions);
  return {
    relationship: relationshipOptions.find(
      (row) => row.value === RELATIONSHIP_OWN_SUSTAINER,
    ),
    names: student.names,
    paternalLastName: student.paternalLastName,
    maternalLastName: student.maternalLastName,
    rut: student.rut ? format(student.rut) : undefined,
    passportCountry: getSelectedOption(
      student?.passportCountry,
      countryOptions,
    ),
    passportNumber: student?.passportNumber,
    addressStreet: student.addressStreet,
    addressNumber: student.addressNumber,
    addressExtra: student.addressExtra,
    commune: selectedCommune,
    region: getSelectedOption(selectedCommune?.regionId, regionOptions),
    phone: student.phone,
    cellphone: student.cellPhone,
    email: student.email,
    nationality: getSelectedOption(
      student?.nationality?.id,
      nationalityOptions,
    ),
    maritalStatus: getSelectedOption(
      student?.maritalStatusId,
      maritalStatusOptions,
    ),
  };
};
export { formatDefaultValues, formatDataOwnSustainer };
