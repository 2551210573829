import { Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import {
  getCreditSummary,
  creditSummaryType,
} from "../../../api/requests/courseCredits";
import { useParams } from "react-router-dom";
import { addToast } from "@octano/global-ui";

const prefix = "courseRegistration.creditSummary";
const CreditSummary = () => {
  const { t } = useTranslation();
  const { studyPlanEnrollmentId } = useParams<{
    studyPlanEnrollmentId: string;
  }>();
  const [creditInfo, setCreditInfo] = useState<creditSummaryType>();

  const getCreditInfo = useCallback(async () => {
    const { data, error } = await getCreditSummary(studyPlanEnrollmentId);
    if (data) {
      setCreditInfo(data.data);
    }
    if (error) {
      addToast({
        color: "danger",
        icon: "error",
        text: t("tuitionContinuity.errorRetrievingInf"),
      });
    }
  }, [studyPlanEnrollmentId, t]);

  useEffect(() => {
    getCreditInfo();
  }, [getCreditInfo]);

  return (
    <Table className="table-summary" borderless responsive>
      <thead>
        <tr>
          <th></th>
          <th>{t(`${prefix}.totalCredits`)}</th>
          <th>{t(`${prefix}.approvedCredits`)}</th>
          <th>{t(`${prefix}.pending`)}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t(`${prefix}.required`)}</td>
          <td>{creditInfo?.totalCredits.obligatory}</td>
          <td>{creditInfo?.approvedCredits.obligatory}</td>
          <td>{creditInfo?.pendingCredits.obligatory}</td>
        </tr>
        <tr>
          <td>{t(`${prefix}.optional`)}</td>
          <td>{creditInfo?.totalCredits.optional}</td>
          <td>{creditInfo?.approvedCredits.optional}</td>
          <td>{creditInfo?.pendingCredits.optional}</td>
        </tr>
        <tr>
          <td>{t(`${prefix}.others`)}</td>
          <td>{creditInfo?.totalCredits.others}</td>
          <td>{creditInfo?.approvedCredits.others}</td>
          <td>{creditInfo?.pendingCredits.others}</td>
        </tr>
        <tr>
          <th scope="row">{t(`${prefix}.total`)}</th>
          <th scope="row">{creditInfo?.totalCredits.total}</th>
          <th scope="row">{creditInfo?.approvedCredits.total}</th>
          <th scope="row">{creditInfo?.pendingCredits.total}</th>
        </tr>
      </tbody>
    </Table>
  );
};

export default CreditSummary;
