import { useCallback, useEffect, useState } from "react";
import { Button, Icon, SearchBox, Table } from "@octano/global-ui";
import { useTranslation } from "react-i18next";
import { deburr } from "lodash";
import SectionList from "./SectionList";
import { requestAvailableCourses } from "../../../api/requests/courseRegistration";
import { CourseType } from "../../../types/courseRegistrationRequestTypes";
import { useDataFilter } from "../../../hooks/useDataFilter";
import { useCourseSelectionState } from "../../../hooks/useCourseSelectionState";

const CourseList = () => {
  const RESULTS_PER_PAGE = 10;
  const prefix = "courseList";
  const { t } = useTranslation();
  const [courses, setCourses] = useState<CourseType[]>([]);
  const filterFunction = useCallback(
    (row: CourseType, text: string) =>
      deburr(row.name).toUpperCase().includes(deburr(text).toUpperCase()) ||
      deburr(row.shortening).toUpperCase().includes(deburr(text).toUpperCase()),
    [],
  );
  const { selectedCourse, setCourseState, selectedCareer } =
    useCourseSelectionState();

  const { results, totalResults, searchText, setSearchText, setPage } =
    useDataFilter<CourseType>(courses, filterFunction, RESULTS_PER_PAGE);

  const getAvailableCourses = useCallback(async () => {
    if (selectedCareer) {
      const { data, error } = await requestAvailableCourses(
        selectedCareer.studyPlanEnrollmentId,
        selectedCareer.mention?.id,
      );
      if (data) {
        setCourses(data);
        setPage(1);
      }
      if (error) {
        setCourses([]);
        // TODO: que mensaje se desplegara al existir un error?
      }
    }
  }, [setPage, selectedCareer]);

  useEffect(() => {
    getAvailableCourses();
  }, [getAvailableCourses, selectedCareer]);

  return (
    <div>
      {selectedCourse?.id ? (
        <SectionList />
      ) : (
        <>
          <Table
            striped={false}
            height={700}
            subHeader={
              <SearchBox
                name="search_course"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder={t(`${prefix}.searchPlaceholder`)}
              />
            }
            columns={[
              {
                columnName: "shortening",
                headerText: t(`${prefix}.code`),
                width: "200px",
              },
              {
                columnName: "name",
                headerText: t(`${prefix}.name`),
                cellFormat: ({ row }) => (
                  <div className="text-uppercase">
                    <span>{row.name}</span>
                    <br />
                    <span className="fs-12 text-light">
                      {row.types.map((type, i) =>
                        i === 0 ? `${type.name} ` : ` - ${type.name}`,
                      )}
                    </span>
                  </div>
                ),
              },
              {
                columnName: "credits",
                headerText: t(`${prefix}.credits`),
              },
              {
                columnName: "id",
                headerText: "",
                cellFormat: ({ row }) => (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setCourseState(row);
                    }}
                  >
                    <Icon name="chevron_right" />
                  </div>
                ),
              },
            ]}
            data={results}
          />

          {totalResults > 0 && results.length < totalResults && (
            <div className="pt-2">
              <Button
                text={t(`${prefix}.btnMore`)}
                size="md"
                onClick={() => setPage((prev) => prev + 1)}
                outlined
                fullwidth
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CourseList;
