import { Dispatch } from "react";
import { SectionType } from "./sectionType";
import { CourseType } from "./courseRegistrationRequestTypes";

export type FacultyType = {
  id: number;
  name: string;
};

export type CampusType = {
  id: number;
  name: string;
};

export type ModalityType = {
  id: number;
  name: string;
};

export type SemesterType = {
  id: number;
  name: string;
};

export type TeacherType = {
  teacher: { id: number };
  fullName: string;
};

export type StudyPlanType = {
  id: number;
  name: string;
  code: string;
  versionId: number;
  versionName: string;
  versionCode: string;
  versionStatus: string;
  curriculumId: number;
  curriculumName: string;
  curriculumCode: string;
  curriculumStatus: string;
  curriculumIsPrimary: boolean;
  showPlanNameOnly?: boolean;
};

export type SectionScheduleType = {
  date: string;
  room: string;
};

export enum SearchingCoursesActionTypes {
  SET_STUDY_PLANS = "SET_STUDY_PLANS",
  SET_COURSES = "SET_COURSES",
  SET_SECTIONS = "SET_SECTIONS",
}

export type SearchingCoursesAction =
  | {
      type: SearchingCoursesActionTypes.SET_STUDY_PLANS;
      payload: StudyPlanType[] | undefined;
    }
  | {
      type: SearchingCoursesActionTypes.SET_COURSES;
      payload: CourseType[] | undefined;
    }
  | {
      type: SearchingCoursesActionTypes.SET_SECTIONS;
      payload: SectionType[] | undefined;
    };

export type SearchingCoursesState = {
  studyPlans: StudyPlanType[] | undefined;
  courses: CourseType[] | undefined;
  sections: SectionType[] | undefined;
};

export type SearchingCoursesContextType = {
  state: SearchingCoursesState;
  dispatch: Dispatch<SearchingCoursesAction>;
};
