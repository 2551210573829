import { useReducer, createContext, FunctionComponent } from "react";
import useCourseSelection, {
  initialState,
} from "../reducers/useCourseSelection";
import { CourseSelectionContextType } from "../types/courseSelectionTypes";

export const CourseSelectionContext = createContext(
  {} as CourseSelectionContextType
);

const CourseSelectionProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(useCourseSelection, initialState);

  return (
    <CourseSelectionContext.Provider value={{ state, dispatch }}>
      {children}
    </CourseSelectionContext.Provider>
  );
};

export default CourseSelectionProvider;
