import { SidenavLayout, SidenavProps } from "@octano/global-ui";
import { CampusFooter } from "../components/CampusFooter/CampusFooter";
import RoutesByLayout from "../components/routes/RoutesByLayout";
import { PathsLayouts } from "../config/routes";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { useUserState } from "../hooks/useUserState";

import { IS_ADDEMIC } from "../config/constants";

import AddemicLogoExpanded from "../assets/addemic/logo_expanded.svg";
import AddemicLogo from "../assets/addemic/logo_small.svg";

export interface TopBarTitlesDict {
  [route: string]: string;
}

export default function AuthorizedLayout(props: { pathname: PathsLayouts }) {
  const { pathname } = props;
  const { t } = useTranslation();
  const { isLogged } = useUserState();

  const SIDENAV_LINKS: SidenavProps["links"] = [
    {
      path: "/student-management/courses",
      name: t(`myCourses.navTitle`),
      icon: "academic_offer",
    },
    {
      path: "/student-management/schedule/",
      name: t(`studentManagement.schedule`),
      icon: "schedule",
    },
    {
      path: PathsLayouts.curricularAdvancement,
      name: t(`curricularAdvancement.navTitle`),
      icon: "academic_progress",
    },
    {
      path: PathsLayouts.myCertificates,
      name: t(`reports.navTitle`),
      icon: "certificates_requests",
    },
    {
      path: PathsLayouts.petitions,
      name: t(`petitions.navTitle`),
      icon: "request",
    },
    {
      path: PathsLayouts.tuitionContinuity,
      name: t(`tuitionContinuity.navTitle`),
      icon: "admision",
    },
    {
      path: PathsLayouts.degreeProcesses,
      name: t(`degreeProcesses.navTitle`),
      icon: "study_plan",
    },
  ];

  if (!isLogged) {
    return <Redirect to={PathsLayouts.auth} />;
  }

  return (
    <SidenavLayout
      links={SIDENAV_LINKS}
      logo={IS_ADDEMIC ? ADDEMIC_LOGOS : CAMPUS_LOGOS}
    >
      <div className="d-flex flex-column h-100">
        <div className="flex-grow-1">
          <RoutesByLayout pathLayout={pathname} />
        </div>
        <CampusFooter />
      </div>
    </SidenavLayout>
  );
}

const CAMPUS_LOGOS = {
  desktop: {
    src: "/tenant/logo_expanded.svg",
    fallbackSrc: "/tenant/logo_expanded.svg",
  },
  mobile: {
    src: "/tenant/logo_small.svg",
    fallbackSrc: "/tenant/logo_small.svg",
  },
};

const ADDEMIC_LOGOS = {
  desktop: {
    src: AddemicLogoExpanded,
    fallbackSrc: AddemicLogoExpanded,
  },
  mobile: {
    src: AddemicLogo,
    fallbackSrc: AddemicLogo,
  },
};
