import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

const ProgressionLegends = (props: { enrolled?: boolean }) => {
  const { enrolled = false } = props;
  const prefix = "statusSemesterCourse";
  const { t } = useTranslation();

  return (
    <Row className='g-legend-progression d-flex'>
      {enrolled && (
        <Col xs={6} sm='auto' className='g-status-container pr-4 py-2'>
          <div className='box-legend box-E mr-2' />
          {t(`${prefix}.enrolled`)}
        </Col>
      )}
      <Col xs={6} sm='auto' className='g-status-container pr-4 py-2'>
        <div className='box-legend box-A mr-2' />
        {t(`${prefix}.approved`)}
      </Col>
      <Col xs={6} sm='auto' className='g-status-container pr-4 py-2'>
        <div className='box-legend box-P mr-2' />
        {t(`${prefix}.pending`)}
      </Col>
      <Col xs={6} sm='auto' className='g-status-container pr-4 py-2'>
        <div className='box-legend box-R mr-2' />
        {t(`${prefix}.failed`)}
      </Col>
      <Col xs={6} sm='auto' className='g-status-container pr-4 py-2'>
        <div className='box-legend box-V mr-2' />
        {t(`${prefix}.validated`)}
      </Col>
    </Row>
  );
};

export default ProgressionLegends;
