import { useCallback, useEffect, useState } from "react";
import { getTariffInfoCalculate } from "../../../../../api/requests/tuitionContinuity";
import DisplayError from "../../../../../components/DisplayError";
import TariffTable from "./TariffTable";
import { TariffCalculateResponse } from "./tariffTypes";

export interface TariffTableLoaderProps {
  studyPlanEnrollmentId: number;
  amount: number;
  quota?: number;
  payday: number;
  resume?: TariffCalculateResponse;
}

export function TariffTableLoader(props: TariffTableLoaderProps) {
  const { studyPlanEnrollmentId, quota, payday, amount } = props;

  const [loading, setLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState<
    "CONNECTION" | "HTTP_ERROR" | string
  >();

  const [data, setData] = useState<TariffCalculateResponse | undefined>(
    props.resume,
  );

  /* Función que se llama cuando se monta el componente. Trae la info utilizada en la vista */
  const getTariffQuotaInfo = useCallback(async () => {
    if (!quota) {
      setData(undefined);
      setErrorLoading(undefined);
      setLoading(false);
      return;
    }

    setLoading(true);

    const tariffResponse = await getTariffInfoCalculate(studyPlanEnrollmentId, {
      installments: quota,
      payday: payday,
      amount: amount,
    });

    if (tariffResponse.data) {
      setData(tariffResponse.data);
      setErrorLoading(undefined);
    }
    if (tariffResponse.error) {
      setErrorLoading(tariffResponse.error.code);
    }
    setLoading(false);
  }, [
    studyPlanEnrollmentId,
    quota,
    payday,
    amount,
    setErrorLoading,
    setLoading,
  ]);

  useEffect(() => {
    getTariffQuotaInfo();
  }, [getTariffQuotaInfo]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => getTariffQuotaInfo()}
        loadingAction={loading}
      />
    );
  }

  return <TariffTable data={data} loading={loading} />;
}
