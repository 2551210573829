import { PARAMETERS } from "../endpoints";
import request from "../request";
import { RegionsAndCommunesBody } from "../../types/parametersTypes";

export const COUNTRY_CHILE_ID = "cl";

/**
 * Obtiene listado de países
 */
export const getCountriesRequest = () => {
  return request<{ id: string; name: string; nationality: string }[]>(
    PARAMETERS.COUNTRIES,
  );
};

/**
 * Obtiene listado de países
 */
export const getIntitutionTypesRequest = () => {
  return request<{ data: { id: string; name: string; description: string }[] }>(
    PARAMETERS.INSTITUTION_TYPES,
  );
};

/**
 * Obtiene el listado de regiones y comunas
 */
export const getRegionsAndCommunesRequest = () =>
  request<RegionsAndCommunesBody>(PARAMETERS.REGION_AND_COMMUNES);
