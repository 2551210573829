import AuthLayout from "../layouts/AuthLayout";
import AuthorizedLayout from "../layouts/AuthorizedLayout";
import CoursesRegistrationLayout from "../layouts/CoursesEnrollmentLayout";
import CoursesSearchingLayout from "../layouts/CoursesSearchingLayout";
import Auth from "../views/auth/Auth";
import ChangePassword from "../views/changePassword/ChangePassword";
import CourseDetail from "../views/courseDetail/CourseDetail";
import CoursesSearching from "../views/coursesSearching/CoursesSearching";
import CurricularAdvancement, {
  CURRICULAR_ADVANCEMENT_TABS_ROUTES,
} from "../views/curricularAdvancement";
import DegreeProcesses from "../views/degreeProcesses";
import EnrolledCoursesView from "../views/enrolledCourses/EnrolledCoursesView";
import MyCourses from "../views/myCourses/MyCourses";
import StudentSchedule from "../views/studentManagement/StudentSchedule";
import StudyProgressionView from "../views/studyProgression/StudyProgressionView";

import ReportsView from "../views/Reports/ReportsView";
import CourseRegistrationView from "../views/courseRegistration/CourseRegistrationView";
import Petitions from "../views/petitions";
import TuitionSteps from "../views/tuitionContinuity/steps";
import TuitionContinuity from "../views/tuitionContinuity/tuitionContinuity";

export enum PathsLayouts {
  auth = "/auth",
  coursesEnrollment = "/enrollment/:studyPlanEnrollmentId",
  coursesSearching = "/courses-searching",
  studentManagement = "/student-management",
  curricularAdvancement = "/curricular-advancement",
  myCertificates = "/my-certificates",
  tuitionContinuity = "/tuition-continuity",
  petitions = "/petitions",
  degreeProcesses = "/degree-processes",
}

export type ViewRouteType = {
  path: string;
  component: () => JSX.Element;
};

export type RoutesByLayout = {
  // this keys are the paths of every layout
  [key in PathsLayouts]: {
    layoutComponent: (props: any) => JSX.Element;
    defaultPath: string;
    views: ViewRouteType[];
  };
};

/**
 * Contiene todos los layouts con sus vistas
 */
const routes: RoutesByLayout = {
  [PathsLayouts.auth]: {
    layoutComponent: AuthLayout,
    defaultPath: "/",
    views: [
      {
        path: "/",
        component: Auth,
      },
      {
        path: "/activate-account",
        component: ChangePassword,
      },
      {
        path: "/reset-password",
        component: ChangePassword,
      },
    ],
  },
  [PathsLayouts.coursesEnrollment]: {
    layoutComponent: CoursesRegistrationLayout,
    defaultPath: "/courses",
    views: [
      {
        path: "/courses",
        component: CourseRegistrationView,
      },
      {
        path: "/detail",
        component: EnrolledCoursesView,
      },
      {
        path: "/progression/:studyPlanVersionId/:curriculumId",
        component: StudyProgressionView,
      },
    ],
  },
  [PathsLayouts.coursesSearching]: {
    layoutComponent: CoursesSearchingLayout,
    defaultPath: "/",
    views: [
      {
        path: "/",
        component: CoursesSearching,
      },
    ],
  },
  [PathsLayouts.studentManagement]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: "/courses",
    views: [
      {
        path: "/courses",
        component: MyCourses,
      },
      {
        path: "/courses/:id",
        component: CourseDetail,
      },
      {
        path: "/schedule",
        component: StudentSchedule,
      },
    ],
  },
  [PathsLayouts.curricularAdvancement]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: "/",
    views: [
      {
        path: `:type(${CURRICULAR_ADVANCEMENT_TABS_ROUTES.STUDENT_GRID}|${CURRICULAR_ADVANCEMENT_TABS_ROUTES.COURSE_HISTORY})`,
        component: CurricularAdvancement,
      },
    ],
  },
  [PathsLayouts.myCertificates]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: "/",
    views: [
      {
        path: `/`,
        component: ReportsView,
      },
    ],
  },
  [PathsLayouts.tuitionContinuity]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: "/",
    views: [
      {
        path: "/",
        component: TuitionContinuity,
      },
      {
        path: "/tuition/step/:studyPlanEnrollmentId",
        component: TuitionSteps,
      },
    ],
  },
  [PathsLayouts.petitions]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: "/",
    views: [
      {
        path: "/",
        component: Petitions,
      },
    ],
  },
  [PathsLayouts.degreeProcesses]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: "/",
    views: [
      {
        path: "/",
        component: DegreeProcesses,
      },
    ],
  },
};

export default routes;
