import { ReactElement, useCallback, useEffect, useState } from "react";

import { AxiosResultDefaultError } from "../../../api/request";
import {
  getStatusStudent,
  STATUS_TO_ENROLL,
  StatusToEnroll,
} from "../../../api/requests/studentManagement";
import DisplayError from "../../../components/info/DisplayError";
import Loading from "../../../components/info/Loading";
import { useLoadingState } from "../../../hooks/useLoadingState";
import { useAcademicInformation } from "../../../components/careerSelector/useAcademicInformation";
export interface StudentStatusLoaderProps {
  children: (props: StatusToEnroll) => ReactElement;
}

export default function StudentStatusLoader({
  children,
}: StudentStatusLoaderProps) {
  const { loading, setLoading } = useLoadingState();
  const [statusToEnroll, setStatusToEnroll] = useState<StatusToEnroll>();
  const [error, setError] = useState<AxiosResultDefaultError>();

  const { selectedCareer } = useAcademicInformation();

  const load = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getStatusStudent(
      selectedCareer.studyPlanEnrollmentId,
    );
    if (error) {
      if (
        error.code !== "CONNECTION" &&
        error?.status === 400 &&
        (error.data.code === STATUS_TO_ENROLL.NOT_DEFINE ||
          error.data.code === STATUS_TO_ENROLL.NOT_FOUND)
      ) {
        setStatusToEnroll(error.data);
      } else {
        setError(error);
      }
    }
    if (data) {
      setError(undefined);
      setStatusToEnroll(data.data);
    }

    setLoading(false);
  }, [setLoading, selectedCareer.studyPlanEnrollmentId]);

  useEffect(() => {
    load();
  }, [load]);

  if (error) {
    return (
      <DisplayError
        insideCard
        body={error.code}
        retryAction={load}
        loadingAction={loading}
      />
    );
  }

  if (loading) {
    return <Loading insideCard />;
  }

  if (!statusToEnroll) {
    return (
      <DisplayError
        insideCard
        body="Data no cargada"
        retryAction={load}
        loadingAction={loading}
      />
    );
  }

  return children(statusToEnroll);
}
