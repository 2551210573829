import {
  OutlinedSelect,
  OutlinedSelectOptionType,
  useMobile,
} from "@octano/global-ui";
import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

import DisplayError from "../info/DisplayError";
import { Career, CareerStatus } from "./academicInformationTypes";
import {
  CareerSelectorProvider,
  useCareerSelector,
} from "./parts/CareerSelectorProvider";
import StudyPlanBox from "./parts/StudyPlanBox";

const format = ({
  id: value,
  name: label,
}: Career): OutlinedSelectOptionType => ({
  value,
  label,
});

export function CareerSelector({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      label: t(`common.terms.studyPlan`),
    }),
    [t],
  );

  const isMobile = useMobile();

  const { careers, selectedCareer, setSelectedCareer, selectedPeriod, setSelectedPeriodByCarrer } = useCareerSelector();

  const options = useMemo(() => careers.map(format), [careers]);

  const selectedOption = useMemo(
    () => (selectedCareer ? format(selectedCareer) : undefined),
    [selectedCareer],
  );
  
  if (!selectedCareer) {
    return <DisplayError body={"No hay una carrera disponible para mostrar"} />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap-reverse",
          gap: 16,
        }}
      >
        <div style={{ width: isMobile ? "100%" : 300 }}>
          {careers.length >= 1 && (
            <OutlinedSelect
              value={selectedOption}
              name="selector"
              label={texts.label}
              options={options}
              isClearable={false}
              onChange={(v) => {
                const career = careers.find((c) => c.id === v.value);
                setSelectedCareer(career!);
                setSelectedPeriodByCarrer(career!)
              }}
            />
          )}
        </div>

        <div className="ml-auto">
          <StudyPlanBox career={selectedCareer} period={selectedPeriod} />
        </div>
      </div>
      {children}
    </>
  );
}

export default function CarrerSelectorWithProvider(props: {
  allowedStatus?: CareerStatus[];
  children: ReactNode;
}) {
  return (
    <CareerSelectorProvider allowedStatus={props.allowedStatus}>
      <CareerSelector {...props} />
    </CareerSelectorProvider>
  );
}
