import { TextInput } from "@octano/global-ui";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { FieldsStudentFile } from "../../../../../types/studentFileTypes";
import { useValidations } from "../../../../../hooks/useValidations";
import SectionTitle from "../../../../../components/text/SectionTitle";

/**
 * Sección de contacto de emergencia
 */
const EmergencyContactSection = () => {
  const { control } = useFormContext<FieldsStudentFile>();
  const prefix = `tuitionContinuity.steps.studentFile.emergencyContactSection`;
  const { t } = useTranslation();
  const { validateEmail, validateTextNotEmpty, validatePhone } =
    useValidations();

  return (
    <>
      <Row className="pt-5">
        <Col className="pb-3" xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="emergencyNames"
            label={t(`${prefix}.names`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="mobilePhoneNumberEmergency"
            label={t(`${prefix}.mobilePhoneNumber`)}
            control={control}
            formatter="phone"
            rules={{
              validate: {
                notEmpty: validateTextNotEmpty,
                phone: validatePhone,
              },
            }}
            placeholder={t(`common.placeholder.cellphone`)}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="emailEmergency"
            label={t(`${prefix}.email`)}
            control={control}
            rules={{
              validate: {
                notEmpty: validateTextNotEmpty,
                email: validateEmail,
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default EmergencyContactSection;
