import { useEffect, useState, useCallback } from "react";
import { Button, Icon, Modal, Table } from "@octano/global-ui";
import { ColumnTable } from "@octano/global-ui/dist/components/Table/types/TableTypes";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { useCourseSelectionState } from "../../../hooks/useCourseSelectionState";
import { requestAvailableSections } from "../../../api/requests/courseRegistration";
import { SectionType } from "../../../types/sectionType";
import HeaderSectionList from "./HeaderSectionList";
import SubheaderSectionList from "./SubheaderSectionList";
import SectionBox from "./SectionBox";

/**
 * Listado de secciones de una asignatura
 */
const prefix = "sectionList";

const SectionList = () => {
  const { t } = useTranslation();
  const {
    selectedCourse,
    setCourseState,
    selectedCampus,
    setSelectedCampus,
    selectedCareer,
  } = useCourseSelectionState();

  const [results, setResults] = useState<SectionType[]>([]);
  const [filteredSections, setFilteredSections] = useState<SectionType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [errorRequirements, setErrorRequirements] = useState<
    { name: string; shortening: string }[] | null
  >(null);
  const [errorType, setErrorType] = useState<"requirements" | "unknown" | null>(
    null,
  );

  const getAvailableSections = useCallback(async () => {
    setIsLoading(true);
    if (selectedCourse?.id && selectedCareer) {
      const { data, error } = await requestAvailableSections({
        courseId: selectedCourse.id,
        studyPlanEnrollmentId: selectedCareer.studyPlanEnrollmentId,
      });
      if (data) {
        setResults(data);
      }
      if (error) {
        // Error de pre-requisitos de asignaturas
        if (
          error?.data?.message?.[0] &&
          JSON.parse(error.data.message[0]).dependOn
        ) {
          setErrorRequirements(JSON.parse(error.data.message[0]).dependOn);
          setErrorType("requirements");
        } else {
          // Mensaje para cualquier otro error ya que no están definidos todos los tipos
          setErrorType("unknown");
        }
      }
    } else {
      setResults([]);
    }
    setIsLoading(false);
  }, [selectedCareer, selectedCourse]);

  useEffect(() => {
    if (results.length > 0 && selectedCampus) {
      setFilteredSections(
        results.filter(
          (section) => section.campus.id === selectedCampus?.value,
        ),
      );
    }
  }, [results, selectedCampus]);

  useEffect(() => {
    getAvailableSections();
  }, [getAvailableSections]);

  const goBack = () => {
    setCourseState(null);
    setSelectedCampus(undefined);
  };

  const columns: ColumnTable<SectionType>[] = [
    {
      columnName: "id",
      headerText: <HeaderSectionList goBackAction={goBack} />,
      cellFormat: (options) => {
        return <SectionBox options={options} />;
      },
    },
  ];

  const toggleModal = () => {
    setErrorType(null);
    setCourseState(null);
    setErrorRequirements(null);
  };

  return (
    <>
      <Table
        striped={false}
        height={700}
        subHeader={<SubheaderSectionList sections={results} />}
        columns={columns}
        data={filteredSections}
        isLoadingResults={isLoading}
        loadingView={{
          title: t(`${prefix}.loadingTitle`),
          subtitle: t(`${prefix}.loadingSubtitle`),
        }}
      />
      {/* MODAL PARA INDICAR ERRORES EN LA INSCRIPCIÓN DE ASIGNATURAS */}
      <Modal isOpen={!!errorType} toggle={toggleModal}>
        <div className="text-center">
          <Row>
            <Col xs={12}>
              <Icon name="warning" size="50px" />
            </Col>
            <Col xs={12} className="pt-4">
              <span className="text-dark fs-22">
                {t(`${prefix}.error.${errorType}.title`)}
              </span>
            </Col>
            <Col xs={12} className="pt-3 pb-4">
              <span className="fs-18 text-light fw-300">
                {t(`${prefix}.error.${errorType}.body`)}
              </span>
            </Col>

            {errorType === "requirements" && errorRequirements && (
              <Col xs={12} className="text-left pb-5">
                <p className="fw-300 text-medium fs-14">
                  {t(`${prefix}.error.requirements.detailTitle`)}
                </p>
                <div
                  className="outlined-box-primary fs-16 text-dark px-1"
                  style={{ minHeight: "120px" }}
                >
                  <ul>
                    {errorRequirements?.map((course, i) => {
                      return <li key={i}>{course.name}</li>;
                    })}
                  </ul>
                </div>
              </Col>
            )}

            <Col xs={12} className="py-1 px-2">
              <Button
                fullwidth
                onClick={toggleModal}
                text={t(`common.btnGotIt`)}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default SectionList;
