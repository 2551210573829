import { useLoadingState } from "../../../../hooks/useLoadingState";
import { useParameters } from "../../../../hooks/useParameters";
import { useCallback, useEffect, useState } from "react";
import { DefaultValues } from "react-hook-form";
import {
  BackgroundSectionType,
  EducationalBackgroundType,
} from "../../../../types/studentFileSectionTypes";
import { FieldsStudentFile } from "../../../../types/studentFileTypes";
import { formatDefaultValuesStudentFile } from "./utils";
import Loading from "../../../../components/info/Loading";
import { Card } from "reactstrap";
import StudentFileForm from "./parts/StudentFileForm";
import DisplayError from "../../../../components/DisplayError";
import { requestTuitionContinuityRecoverStudentFile } from "../../../../api/requests/tuitionContinuity";
import { useParams } from "react-router-dom";
import SectionTitle from "../../../../components/text/SectionTitle";
import { useTranslation } from "react-i18next";

function StudentFile() {
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  useState<{ id: string; name: string }>();
  const prefix = "tuitionContinuity.steps.studentFile";
  const { t } = useTranslation();
  const [backgroundData, setBackgroundData] = useState<BackgroundSectionType>();
  const [educationalBackground, setEducationalBackground] =
    useState<EducationalBackgroundType>();
  const [defaultValues, setDefaultValues] =
    useState<DefaultValues<FieldsStudentFile>>();
  const {
    countryOptions,
    communeOptions,
    regionOptions,
    institutionTypeOptions,
    loadCountries,
    loadRegionsAndCommunes,
    loadInstitutionTypes,
  } = useParameters();

  const { studyPlanEnrollmentId } = useParams<{
    studyPlanEnrollmentId: string;
  }>();

  const getStudentFileInfo = useCallback(async () => {
    setLoading(true);
    const { data, error } = await requestTuitionContinuityRecoverStudentFile(
      studyPlanEnrollmentId,
    );
    if (data) {
      setDefaultValues(
        formatDefaultValuesStudentFile(
          data.data,
          communeOptions,
          regionOptions,
          countryOptions,
          institutionTypeOptions,
        ),
      );
      setBackgroundData({
        student: data.data.student,
        studyPlan: data.data.studyPlan,
        postulation: data.data.postulation,
      });
      setEducationalBackground(data.data.educationalBackground);
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
    }
    setLoading(false);
  }, [
    setErrorLoading,
    setLoading,
    countryOptions,
    communeOptions,
    regionOptions,
    institutionTypeOptions,
    studyPlanEnrollmentId,
  ]);

  useEffect(() => {
    if (countryOptions.length === 0) {
      loadCountries();
    }
  }, [countryOptions, loadCountries]);

  useEffect(() => {
    loadRegionsAndCommunes();
  }, [loadRegionsAndCommunes]);

  useEffect(() => {
    loadInstitutionTypes();
  }, [loadInstitutionTypes]);

  useEffect(() => {
    if (
      loading &&
      countryOptions.length > 0 &&
      regionOptions.length > 0 &&
      communeOptions.length > 0 &&
      institutionTypeOptions.length > 0
    ) {
      getStudentFileInfo();
    }
  }, [
    loading,
    getStudentFileInfo,
    countryOptions,
    regionOptions,
    communeOptions,
    institutionTypeOptions,
  ]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <Card className="px-4 px-md-5 py-4">
      <SectionTitle text={t(`${prefix}.instructionTitle`)} />
      <p>{t(`${prefix}.instructions`)}</p>
      <StudentFileForm
        backgroundData={backgroundData}
        educationalBackground={educationalBackground}
        defaultValues={defaultValues}
      />
    </Card>
  );
}

export default StudentFile;
