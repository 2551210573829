import { Icon, TextInput } from "@octano/global-ui";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

export interface FormViewProps {
  date: string;
  graduationGrade: string;
  degreeGrade?: string;
  distinctionRank: string;
}

const dictPrefix = "degreeProcesses";

export default function FormView(props: FormViewProps) {
  const { t } = useTranslation();

  const texts = useMemo(
    () => ({
      distinctionRankLabel: t(`${dictPrefix}.form.distinctionRank`),
      graduationGradeLabel: t(`${dictPrefix}.form.graduationGrade`),
      degreeGradeLabel: t(`${dictPrefix}.form.degreeGrade`),
      dateLabel: t(`${dictPrefix}.form.date`),
    }),
    [t],
  );

  return (
    <>
      <Row>
        <Col className="pb-3" xs={12} lg={6}>
          <TextInput
            name="distinctionRank"
            label={texts.distinctionRankLabel}
            value={props.distinctionRank}
            readOnly
          />
        </Col>
        <Col className="pb-3" xs={12} lg={3}>
          <TextInput
            name="graduationGrade"
            label={texts.graduationGradeLabel}
            value={props.graduationGrade}
            readOnly
          />
        </Col>
        <Col className="pb-3" xs={12} lg={3}>
          <TextInput
            name="degreeGrade"
            label={texts.degreeGradeLabel}
            value={props.degreeGrade}
            readOnly
          />
        </Col>
        <Col className="pb-3" xs={12}>
          <p className="text-dark">
            <Icon name="success" color="success" /> Fecha de titulación:{" "}
            {dayjs(props.date).format("DD/MM/YYYY")}
          </p>
        </Col>
      </Row>
    </>
  );
}
