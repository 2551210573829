import { SelectOptionType } from "@octano/global-ui";
import { useEffect, useState } from "react";
import { useParameters } from "./useParameters";

/**
 * Obtiene el listado filtrado de opciones de comunas
 * según la región seleccionada
 * @param selectedRegion
 * @returns listado de opciones de comunas
 */
export const useFilteredCommunes = (
  selectedRegion: SelectOptionType | undefined,
) => {
  const [filteredCommunes, setFilteredCommunes] = useState<SelectOptionType[]>(
    [],
  );
  const { communeOptions } = useParameters();

  useEffect(() => {
    const communes = communeOptions.filter(
      (commune) => commune.regionId === selectedRegion?.value,
    );
    setFilteredCommunes(communes);
  }, [selectedRegion, communeOptions]);

  return filteredCommunes;
};
