import { Card } from "reactstrap";
import { useStepState } from "../../../hooks/useStepState";
import { useCreateTuitionContinuitySteps } from "../../../hooks/useCreateTuitionContinuitySteps";
import { addToast, Steps } from "@octano/global-ui";
import withBasicProvider from "../../../hoc/withBasicProvider";
import StepProvider from "../../../providers/StepProvider";
import StudentFiles from "./studentFiles";
import React, { useCallback, useEffect, useState } from "react";
import { requestTuitionContinuityCurrentStep } from "../../../api/requests/tuitionContinuity";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../../components/info/Loading";
import Sustainer from "./sustainer";
import TariffStep from "./tariff";
import SignDocumentationStep from "./signDocumentation";
import Completed from "./completed";
import TopBar from "../../../components/topBar/TopBar";
import { TcProcessEvent } from "../../../types/tuitionContinuity";
import { PathsLayouts } from "../../../config/routes";
const allAvailableSteps = [
  {
    id: "student-file",
    component: StudentFiles,
  },
  {
    id: "sustainer",
    component: Sustainer,
  },
  {
    id: "tariff",
    component: TariffStep,
  },
  {
    id: "sign-documentation",
    component: SignDocumentationStep,
  },
  {
    id: "completed",
    component: Completed,
  },
];
function TuitionSteps() {
  const { t } = useTranslation();
  const { studyPlanEnrollmentId } = useParams<{
    studyPlanEnrollmentId: string;
  }>();
  const { namesSteps, CurrentStepComponent, setAvailableStepsAndCurrentIndex } =
    useCreateTuitionContinuitySteps({
      translationPrefix: "tuitionContinuity.steps.titleSteps",
      allAvailableSteps,
    });

  const { currentStep, setCurrentStep } = useStepState();

  const history = useHistory();

  const [isLoading, setLoading] = useState<boolean>(true);

  const getCurrentStepData = useCallback(async () => {
    setLoading(true);
    const { data, error } = await requestTuitionContinuityCurrentStep(
      studyPlanEnrollmentId,
    );
    if (error) {
      addToast({
        color: "danger",
        icon: "error",
        text: t("tuitionContinuity.errorRetrievingInf"),
      });
    }
    if (data) {
      const index = setAvailableStepsAndCurrentIndex(
        data.data.steps,
        data.data.currentStep,
      );
      setCurrentStep(index);
    }
    setLoading(false);
  }, [
    studyPlanEnrollmentId,
    t,
    setAvailableStepsAndCurrentIndex,
    setCurrentStep,
  ]);

  useEffect(() => {
    getCurrentStepData();
  }, [getCurrentStepData]);
  useEffect(() => {
    window.addEventListener(TcProcessEvent.TC_PROCESS_UNAUTHORIZED, () => {
      history.push(PathsLayouts.tuitionContinuity);
    });
  }, [history]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <TopBar title={t("tuitionContinuity.title")} />
          <div className="w-100 mb-4 p-0">
            <Steps
              color="secondary"
              currentStep={currentStep}
              steps={namesSteps}
            />
          </div>
          <Card className="p-4">
            {CurrentStepComponent && <CurrentStepComponent />}
          </Card>
        </>
      )}
    </>
  );
}

export default withBasicProvider(StepProvider)(TuitionSteps);
