import {
  CarouselResponse,
  StudentStudyPlan,
} from "../../types/studentManagementTypes";
import { StudentAttendanceSummary } from "../../types/attendance";
import { STUDENTS, STUDENT_MANAGEMENT } from "../endpoints";
import request from "../request";

export const requestStudentStudyPlanInfo = () => {
  return request<StudentStudyPlan>(STUDENT_MANAGEMENT.STUDY_PLAN_INFORMATION, {
    method: "GET",
  });
};

export const requestStudentSectionsInfo = (
  studyPlanEnrollmentId: number | string,
) => {
  return request(
    STUDENT_MANAGEMENT.SECTIONS_INFORMATION(studyPlanEnrollmentId),
    { method: "GET" },
  );
};

export const getStudentAttendanceSummary = ({
  studentId,
  sectionId,
  page = 0,
  itemsPerPage = Number.MAX_SAFE_INTEGER,
}: {
  studentId: number | string;
  sectionId: number | string;
  page?: number;
  itemsPerPage: number;
}) => {
  const url = STUDENT_MANAGEMENT.GET_ATTENDANCE_SUMMARY(studentId, sectionId);
  const params = { studentId, items_per_page: itemsPerPage, page };
  return request<StudentAttendanceSummary>(url, { params });
};

export const requestCarouselImages = (carouselId: number) => {
  return request<{ data: CarouselResponse }>(
    STUDENT_MANAGEMENT.GET_CAROUSEL_IMAGES + "/" + carouselId,
    {
      method: "get",
    },
  );
};

export enum STATUS_TO_ENROLL {
  INVALID = "INVALID_STUDENT_STATUS",
  OK = "OK",
  DEBTOR = "STUDENT_IN_DEBT",
  INCOMING = "INCOMING_EVENT",
  EXCEEDED = "PERIOD_EXCEEDED",
  NOT_DEFINE = "EVENT_NOT_DEFINE",
  NOT_FOUND = "PERIOD_NOT_FOUND",
}

export type StatusToEnroll =
  | { code: STATUS_TO_ENROLL.NOT_FOUND; email: string }
  | { code: STATUS_TO_ENROLL.NOT_DEFINE; email: string }
  | {
      code:
        | STATUS_TO_ENROLL.INCOMING
        | STATUS_TO_ENROLL.OK
        | STATUS_TO_ENROLL.EXCEEDED
        | STATUS_TO_ENROLL.INVALID
        | STATUS_TO_ENROLL.DEBTOR;
      email: string;
      range: string[];
    };

export const getStatusStudent = (studyPlanEnrollmentId: number) => {
  const url = STUDENTS.CURRENT_STATUS;
  const params = {
    studyPlanEnrollmentId,
  };
  return request<{
    data: StatusToEnroll;
  }>(url, { params });
};
