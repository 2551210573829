import { Button } from "@octano/global-ui";
import { Fragment, useState } from "react";
import StudentPhotoModal, { StudentPhotoModalTexts } from "./StudentPhotoModal";

export interface StudentPhotoProps {
  defaultPhoto?: string | null;
  texts: StudentPhotoTexts;
}

export interface StudentPhotoTexts extends StudentPhotoModalTexts {
  upload: string;
  reupload: string;
}

const StudentPhoto = (props: StudentPhotoProps) => {
  const { texts, defaultPhoto: defaultPhotoProp } = props;

  const [defaultPhoto, setDefaultPhoto] = useState(defaultPhotoProp);

  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <div className="image-square-container">
        <div className="image-square">
          <img
            src={defaultPhoto || "/images/no-photos.png"}
            className="img img-responsive full-width"
            alt="student"
          />
        </div>
        <Button
          className="position-relative"
          text={defaultPhoto ? texts.reupload : texts.upload}
          size="sm"
          color="primary"
          disabled={true}
          fullwidth
          onClick={() => {
            setOpen(true);
          }}
        />
      </div>
      <StudentPhotoModal
        open={open}
        onClose={() => setOpen(false)}
        currentPhoto={defaultPhoto}
        texts={texts}
        onSave={(src) => {
          setOpen(false);
          setDefaultPhoto(src);
        }}
      />
    </Fragment>
  );
};

export default StudentPhoto;
