import {
  SaveStudentFileBody,
  StudentFileResponse,
} from "../../../../types/studentFileSectionTypes";
import { FieldsStudentFile } from "../../../../types/studentFileTypes";
import { getSelectedOption } from "../../../../utils/selectFormat";
import { SelectOptionType } from "@octano/global-ui";
import { DefaultValues } from "react-hook-form";
import {
  educationalLevelOptions,
  familyGroupOptions,
  healthCareProviderOptions,
  healthCareSystemOptions,
  healthInsuranceOptions,
  maritalStatusOptions,
  parentalSurvivalOptions,
  sexCIOptions,
} from "../../../../utils/selectOptions";

import { format as formatRut } from "rut.js";

const checkLength = (value: any) =>
  Object.values(value).filter((item) => item !== undefined && item !== null)
    .length === 0;

export function formatStudentFileData(values: FieldsStudentFile) {
  const formatData: SaveStudentFileBody = {
    background: {
      sexId: +values.sexID.value,
      maritalStatusId: +values.maritalStatus.value,
      nationalityId: values.nationality.value.toString(),
    },
    contact: {
      addressStreet: values.addressStreet,
      addressNumber: values.addressNumber,
      addressExtra: values.addressExtra,
      communeId: values.commune?.value as number,
      phone: values.phone,
      cellPhone: values.cellphone,
      email: values.email,
    },
    familyContact: {
      addressStreet: values.addressStreetFamily,
      addressNumber: values.addressNumberFamily,
      addressExtra: values.addressExtraFamily,
      communeId: +values.communeFamily.value,
      phone: values.phoneFamily,
      cellPhone: values.cellphoneFamily,
      email: values.emailFamily,
    },
    emergencyContact: {
      name: values.emergencyNames,
      cellPhone: values.mobilePhoneNumberEmergency,
      email: values.emailEmergency,
    },
    healthCare: {
      systemId: values.healthCareSystem?.value as number,
      providerId: values.healthCareProvider?.value as number,
      contributorRut: values.healthCareContributorID,
      hasInsurance:
        typeof values.healthInsurance?.value === "number"
          ? Boolean(values.healthInsurance?.value)
          : undefined,
      insuranceContributorRut: values.healthInsuranceContributorID,
      insuranceCellPhone: values.phoneNumberHealthCare,
      otherSystem: values.otherHealthCare ?? undefined,
    },
    parentalEducationalBackground: {
      educationalLevelPaternalId: values.fatherEducationalLevel
        ?.value as number,
      educationalLevelMaternalId: values.motherEducationalLevel
        ?.value as number,
      familyGroupId: values.familyGroup?.value as number,
      parentalSurvivalId: values.parentalSurvival?.value as number,
    },
    educationalBackground: {
      highSchool: values.nameHighSchool,
      graduationYear: +values.graduationYear,
      countryId: values.countryHighSchool!.value.toString(),
      communeId: values.communeHighSchool
        ? +values.communeHighSchool.value
        : null,
      institutionTypeId: +values.typeHighSchool!.value,
    },
  };

  if (checkLength(formatData.contact)) {
    formatData.contact = undefined;
  }
  if (checkLength(formatData.healthCare)) {
    formatData.healthCare = undefined;
  }
  if (checkLength(formatData.parentalEducationalBackground)) {
    formatData.parentalEducationalBackground = undefined;
  }
  return formatData;
}
export const formatDefaultValuesStudentFile = (
  data: StudentFileResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
  countries: SelectOptionType[],
  institutionTypes: SelectOptionType[],
): DefaultValues<FieldsStudentFile> => {
  return {
    ...formatCommonDefaultValuesStudentFile(
      data,
      communeOptions,
      regionOptions,
    ),
    nationality: getSelectedOption(data.student?.nationality?.id, countries),

    nameHighSchool: data.educationalBackground.highSchool,
    countryHighSchool: getSelectedOption(
      data.educationalBackground?.country?.id,
      countries,
    ),
    regionHighSchool: getSelectedOption(
      data.educationalBackground?.region?.id,
      regionOptions,
    ),
    communeHighSchool: getSelectedOption(
      data.educationalBackground?.commune?.id,
      communeOptions,
    ),
    typeHighSchool: getSelectedOption(
      data.educationalBackground.institutionType?.id,
      institutionTypes,
    ),
    graduationYear: data.educationalBackground.graduationYear,
  };
};

export const formatCommonDefaultValuesStudentFile = (
  data: StudentFileResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
) => {
  const selectedCommune = getSelectedOption(
    data.contact?.communeId,
    communeOptions,
  );
  const selectedCommuneFamily = getSelectedOption(
    data.familyContact?.communeId,
    communeOptions,
  );

  return {
    sexID: getSelectedOption(data.student.sexId ?? undefined, sexCIOptions),
    maritalStatus: getSelectedOption(
      data.student.maritalStatusId ?? undefined,
      maritalStatusOptions,
    ),
    addressStreet: data.contact?.addressStreet,
    addressNumber: data.contact?.addressNumber,
    addressExtra: data.contact?.addressExtra ?? undefined,
    region: getSelectedOption(selectedCommune?.regionId, regionOptions),
    commune: selectedCommune,
    phone: data.contact?.phone ?? undefined,
    cellphone: data.contact?.cellPhone,
    email: data.contact?.email,
    addressStreetFamily: data.familyContact?.addressStreet,
    addressNumberFamily: data.familyContact?.addressNumber,
    addressExtraFamily: data.familyContact?.addressExtra ?? undefined,
    regionFamily: getSelectedOption(
      selectedCommuneFamily?.regionId,
      regionOptions,
    ),
    communeFamily: selectedCommuneFamily,
    phoneFamily: data.familyContact?.phone ?? undefined,
    cellphoneFamily: data.familyContact?.cellPhone,
    emailFamily: data.familyContact?.email,
    emergencyNames: data.emergencyContact?.name,
    mobilePhoneNumberEmergency: data.emergencyContact?.cellPhone,
    emailEmergency: data.emergencyContact?.email,
    fatherEducationalLevel: getSelectedOption(
      data.parentalEducationalBackground?.educationalLevelPaternalId,
      educationalLevelOptions,
    ),
    motherEducationalLevel: getSelectedOption(
      data.parentalEducationalBackground?.educationalLevelMaternalId,
      educationalLevelOptions,
    ),
    familyGroup: getSelectedOption(
      data.parentalEducationalBackground?.familyGroupId,
      familyGroupOptions,
    ),
    parentalSurvival: getSelectedOption(
      data.parentalEducationalBackground?.parentalSurvivalId,
      parentalSurvivalOptions,
    ),
    healthCareSystem: getSelectedOption(
      data.healthCare?.systemId,
      healthCareSystemOptions,
    ),
    healthCareProvider: getSelectedOption(
      data.healthCare?.providerId ?? undefined,
      healthCareProviderOptions,
    ),
    healthCareContributorID: data.healthCare?.contributorRut
      ? formatRut(data.healthCare.contributorRut)
      : undefined,
    healthInsurance: getSelectedOption(
      data.healthCare?.hasInsurance !== undefined
        ? +data.healthCare?.hasInsurance
        : undefined,
      healthInsuranceOptions,
    ),
    healthInsuranceContributorID: data.healthCare?.insuranceContributorRut
      ? formatRut(data.healthCare.insuranceContributorRut)
      : undefined,
    phoneNumberHealthCare: data.healthCare?.insuranceCellPhone ?? undefined,
    otherHealthCare: data.healthCare?.otherSystem,
  };
};
