import { COURSE_ENROLLMENT, COURSE_SEARCHING } from "../endpoints";
import request from "../request";

export interface ModuleType {
  id: number;
  name: string;
  shortening: string;
  startTime: string;
  endTime: string;
  createdAt: string;
  updatedAt: string;
}

/**
 * Valida que el usuario ingresado sea un usuario del producto.
 * @param data
 * @returns
 */
export const getModules = () => {
  return request<{ data: ModuleType[] }>(COURSE_ENROLLMENT.MODULES, {
    method: "get",
  });
};

/**
 * Valida que el usuario ingresado sea un usuario del producto.
 * @param data
 * @returns
 */
export const getModulesWithoutAuth = () => {
  return request<{ data: ModuleType[] }>(
    COURSE_SEARCHING.CALENDAR_TIME_MODULES,
    {
      method: "get",
    }
  );
};
