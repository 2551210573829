import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";

import {
  CurricularProgressionResponse,
  requestCurriculumProgression,
} from "../../api/requests/curriculum";
import StudyPlanBox from "../../components/box/StudyPlanBox";
import ButtonBack from "../../components/button/ButtonBack";
import DisplayError from "../../components/info/DisplayError";
import Loading from "../../components/info/Loading";
import ProgressionBySemester from "../../components/progression/ProgressionBySemester";
import ProgressionLegends from "../../components/progression/ProgressionLegends";
import SectionTitle from "../../components/text/SectionTitle";
import Title from "../../components/text/Title";
import CurriculumResume from "./parts/CurriculumResume";

enum ERROR_TYPE {
  NOT_FOUND = "NOT_FOUND",
  UNKNOWN = "UNKNOWN",
}
const prefix = "studyProgression";

const StudyProgression = () => {
  const { t } = useTranslation();
  const { studyPlanEnrollmentId } = useParams<{
    studyPlanEnrollmentId: string;
  }>();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [errorData, setErrorData] = useState<ERROR_TYPE | null>(null);
  const [progressionData, setProgressionData] =
    useState<CurricularProgressionResponse>();

  /**
   * Obtiene la información de la malla curricular y el avance
   * del alumno en ésta
   */
  const getData = useCallback(async () => {
    const { data, error } = await requestCurriculumProgression(
      studyPlanEnrollmentId,
    );

    if (data) {
      setProgressionData(data.progress);
      setErrorData(null);
    }

    if (error) {
      if (error.data.error === "Not Found") {
        setErrorData(ERROR_TYPE.NOT_FOUND);
      } else {
        setErrorData(ERROR_TYPE.UNKNOWN);
      }
    }
    setLoading(false);
  }, [studyPlanEnrollmentId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const mentionName: string | null = useMemo(() => {
    if (!progressionData?.isPrimary && progressionData?.name) {
      return progressionData.name;
    }
    return null;
  }, [progressionData]);

  if (loading) {
    return <Loading insideCard />;
  }
  if (errorData) {
    return (
      <DisplayError
        insideCard
        title={t(`${prefix}.errorData.${errorData}.title`)}
        body={t(`${prefix}.errorData.${errorData}.body`)}
      />
    );
  }
  return (
    <>
      <Title text={t(`${prefix}.title`)} />
      <Card className="mt-3 py-4 px-3 px-md-5">
        <Row>
          <Col xs={12} sm={3} className="pb-2">
            <ButtonBack onClick={() => history.goBack()} />
          </Col>
          <Col xs={12} sm={9} className="pb-2">
            <StudyPlanBox
              studyPlan={progressionData?.studyPlanVersion.name || ""}
              mention={mentionName}
            />
          </Col>
        </Row>

        {/* DATOS DE LA MALLA */}
        <CurriculumResume data={progressionData} />

        {/* MALLA DE ESTUDIOS */}
        <Row>
          <Col xs={12} className="pt-4 pb-3">
            <SectionTitle text={t(`${prefix}.studyPlanVersion.title`)} />
          </Col>
          <Col xs={12}>
            <ProgressionBySemester
              semesters={progressionData?.semesters || []}
            />
          </Col>
          <Col xs={12} className="py-5">
            <ProgressionLegends />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default StudyProgression;
